import React, { useState } from 'react';

import Section from '../../components/Section/Section';
import Description from '../../components/Description';
import sections from '../../constants/sections';
import Content from '../../components/Content';
import Video from '../../components/Video';

import FullImage from '../../components/FullImage';
import Footer from '../../components/Footer/Footer';

import devicesImage from '../../assets/images/lsa/lsa-devices.jpg';
import devicesMobileImage from '../../assets/images/lsa/lsa-devices-mobile.jpg';
import fontsImage from '../../assets/images/lsa/lsa-fonts.jpg';
import fontsMobileImage from '../../assets/images/lsa/lsa-fonts-mobile.jpg';
import web1Image from '../../assets/images/lsa/lsa-website-1.jpg';
import web1MobileImage from '../../assets/images/lsa/lsa-website-1-mobile.jpg';
import web2Image from '../../assets/images/lsa/lsa-website-2.jpg';
import web2MobileImage from '../../assets/images/lsa/lsa-website-2-mobile.jpg';
import web3Image from '../../assets/images/lsa/lsa-website-3.jpg';
import web3MobileImage from '../../assets/images/lsa/lsa-website-3-mobile.jpg';
import bossImage from '../../assets/images/lsa/lsa-boss.jpg';
import bossMobileImage from '../../assets/images/lsa/lsa-boss-mobile.jpg';
import webScreenImage from '../../assets/images/lsa/lsa-web-screens.jpg';
import webScreenMobileImage from '../../assets/images/lsa/lsa-web-screens-mobile.jpg';
import screen1Image from '../../assets/images/lsa/lsa-screens-1.jpg';
import screen1MobileImage from '../../assets/images/lsa/lsa-screens-1-mobile.jpg';
import screen2Image from '../../assets/images/lsa/lsa-screens-2.jpg';
import screen2MobileImage from '../../assets/images/lsa/lsa-screens-2-mobile.jpg';
import specsImage from '../../assets/images/lsa/lsa-specs.jpg';
import specsMobileImage from '../../assets/images/lsa/lsa-specs-mobile.jpg';
import mobileScreensImage from '../../assets/images/lsa/lsa-mobile-screens.jpg';
import mobileScreensMobileImage from '../../assets/images/lsa/lsa-mobile-screens-mobile.jpg';

import lsaVideo from '../../assets/videos/lsa.mp4';
import { useWindowDimensions } from '../../utils/window';

const Lsa = () => {
  const name = 'Lsa';
  const { description, mainColor } = sections[name];
  const [loadedImageCount, setLoadedImageCount] = useState(0);
  const { width } = useWindowDimensions();
  const itemCount = width < 768 ? 11 : 12;

  return (
    <Section
      sectionName={name}
      itemCount={itemCount}
      loadedImageCount={loadedImageCount}
    >
      <Description desc={description} />
      <div>
        <FullImage
          mainColor={mainColor}
          image={devicesImage}
          mobileImage={devicesMobileImage}
          alt='Lsa devices'
          loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
          data-top='0.5'
          className='scroll-animation-elem'
          data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        />
      </div>
      <FullImage
        mainColor={mainColor}
        image={fontsImage}
        mobileImage={fontsMobileImage}
        alt='Lsa fonts'
        loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
        // className='scroll-animation-elem'
        // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
      />
      <Content isFull backgroundColor='#cac5c1' isShown={true}>
        <div
        // className='scroll-animation-elem'
        // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        >
          <FullImage
            mainColor={mainColor}
            image={web1Image}
            mobileImage={web1MobileImage}
            alt='Lsa fonts'
            loadCallBack={() =>
              setLoadedImageCount((prevcount) => prevcount + 1)
            }
          />
          <FullImage
            mainColor={mainColor}
            image={web2Image}
            mobileImage={web2MobileImage}
            alt='Lsa fonts'
            loadCallBack={() =>
              setLoadedImageCount((prevcount) => prevcount + 1)
            }
          />
          <FullImage
            mainColor={mainColor}
            image={web3Image}
            mobileImage={web3MobileImage}
            alt='Lsa fonts'
            loadCallBack={() =>
              setLoadedImageCount((prevcount) => prevcount + 1)
            }
          />
        </div>
        <FullImage
          mainColor={mainColor}
          image={bossImage}
          mobileImage={bossMobileImage}
          alt='Lsa boss'
          loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
          // className='scroll-animation-elem'
          // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        />
        <Content backgroundColor='#d2cdc9' isShown={true}>
          <FullImage
            mainColor={mainColor}
            image={webScreenImage}
            mobileImage={webScreenMobileImage}
            alt='Lsa screens'
            loadCallBack={() =>
              setLoadedImageCount((prevcount) => prevcount + 1)
            }
            // className='scroll-animation-elem'
            // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
          />
          <div
          // className='scroll-animation-elem'
          // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
          >
            <FullImage
              mainColor={mainColor}
              image={screen1Image}
              mobileImage={screen1MobileImage}
              alt='Lsa screen'
              loadCallBack={() =>
                setLoadedImageCount((prevcount) => prevcount + 1)
              }
            />
            <FullImage
              mainColor={mainColor}
              image={screen2Image}
              mobileImage={screen2MobileImage}
              alt='Lsa screen'
              loadCallBack={() =>
                setLoadedImageCount((prevcount) => prevcount + 1)
              }
            />
          </div>
        </Content>
      </Content>
      <Content isFull backgroundColor='#fff' isShown={true}>
        <FullImage
          mainColor={mainColor}
          image={specsImage}
          mobileImage={specsMobileImage}
          alt='Lsa specs'
          loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
          // className='scroll-animation-elem'
          // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        />
        <Content backgroundColor='#fff' isShown={true} isFull={true}>
          <Video
            // image={videoImage}
            // mobileImage={videoMobileImage}
            isFull
            video={lsaVideo}
            mobileVideo={lsaVideo}
            onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
            // className='scroll-animation-elem'
            // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
          />
        </Content>
      </Content>
      <Content isFull backgroundColor='#cac5c1' isShown={true}>
        <FullImage
          mainColor={mainColor}
          image={mobileScreensImage}
          mobileImage={mobileScreensMobileImage}
          alt='Lsa app screens'
          loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
          // className='scroll-animation-elem'
          // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        />
      </Content>

      <Footer />
    </Section>
  );
};

export default Lsa;
