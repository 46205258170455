import React from 'react';
import { useSelector } from 'react-redux';

import sections from '../../constants/sections';
import {
  selectMainPageActiveIndex,
  selectSelectedSectionIndex,
} from '../../features/selectors';
import { P } from './Pagination.styles';

const Pagination = () => {
  const mainPageActiveIndex = useSelector(selectMainPageActiveIndex);
  const selectedSectionIndex = useSelector(selectSelectedSectionIndex);

  return (
    <P.Container
      id='uc-pagination'
      className={
        mainPageActiveIndex > 0 && selectedSectionIndex < 0 ? 'active' : ''
      }
    >
      {Object.keys(sections).map((key, index) => {
        const sec = sections[key];
        return (
          <div key={index}>
            {sec.index !== 0 && sec.index !== 10 && (
              <P.PageLine
                className={mainPageActiveIndex === sec.index ? 'active' : ''}
              ></P.PageLine>
            )}
          </div>
        );
      })}
    </P.Container>
  );
};

export default Pagination;
