const colors = {
  black: '#000',
  white: '#fff',
  eggBlue: '#F4F9FB',
  flavorWhite: '#fbf9f4',
  lightRed: '#FCCEBF',
  alabaster: '#EEEEE9',
  water: '#d5f5f1',
  mercury: '#e7e7e7',
  cream: '#F8F7F5',
};

export default colors;
