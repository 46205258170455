import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsHomeTextGone } from '../../features/selectors';

import Text from '../Text/Text';
import VerticalAnimationItem from '../VerticalAnimationItem/VerticalAnimationItem';
import { N } from './Names.styles';

export default function Names({
  title,
  fontSize = 'xl',
  as = 'h3',
  sectionIndex,
  ...others
}) {
  const isHomeTextGone = useSelector(selectIsHomeTextGone);

  return (
    <VerticalAnimationItem sectionIndex={sectionIndex} {...others}>
      <N.Container className={isHomeTextGone ? 'gone' : ''}>
        <div>
          <div>
            <Text
              as={as}
              size={fontSize}
              font='bold'
              className={title.split('\u00A0').join('-')}
            >
              {title.split(' ')[0]}
            </Text>
          </div>
        </div>
        <div>
          <div>
            <Text as={as} size={fontSize} font='bold'>
              {title.split(' ')[1]}
            </Text>
          </div>
        </div>
      </N.Container>
    </VerticalAnimationItem>
  );
}
