import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsDetailHeaderAndContentShown } from '../../features/selectors';
import { wrapTextToSpan, _spanToText } from '../../utils/textConvertor';

import Text from '../Text/Text';
import { D } from './Description.styles';

export default function Description({ desc, ...props }) {
  const isDetailHeaderAndContentShown = useSelector(
    selectIsDetailHeaderAndContentShown
  );

  useEffect(() => {
    const desc = document.getElementById('section-detail-desc');
    if (isDetailHeaderAndContentShown) {
      setTimeout(() => {
        wrapTextToSpan(desc);
      }, 600);
    }
    return () => {
      _spanToText(desc);
    };
  }, [isDetailHeaderAndContentShown]);

  return (
    <D.Container {...props}>
      <Text
        size='lx'
        id='section-detail-desc'
        className='scroll-animation-elem childs'
        data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        data-text='wrapper'
        data-top='0.7'
      >
        {desc}
      </Text>
    </D.Container>
  );
}
