import styled from "@emotion/styled"
import theme from "../../theme"

export const S = {}

S.Container = styled.div({
  width: 50,
  height: 50,
  borderRadius: 25,
  backgroundColor: theme.colors.black,
  "& a": {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
})
