import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isMainLoaded: false,
  isPageAnimating: false,
  isPageReset: false,
  mainPageActiveIndex: -1,
  mainPageScrolldirection: 'down',
  isMainPageScrolling: false,
  isBioPageOpen: false,
  selectedSectionIndex: -1,
  isHeaderBgGone: false,
  isHomeTextGone: true,
  isBannerRestShown: false,
  isDetailHeaderAndContentShown: false,
  isImagesLoading: false,
  allBannerImageLoadedCount: [],
};

const animationsSlice = createSlice({
  name: 'animations',
  initialState,
  reducers: {
    resetSlice: (state, payload) => {
      state = payload.state;
    },
    updateMainPageIndex: (state, { payload: { index, direction } }) => {
      state.mainPageActiveIndex = index;
      state.mainPageScrolldirection = direction;
    },
    updateBioPageOpenStatus: (state, { payload: { status } }) => {
      state.isBioPageOpen = status;
    },
    updateSelectedSectionIndex: (state, { payload: { index } }) => {
      state.selectedSectionIndex = index;
    },
    updateHeaderBgGone: (state, { payload: { status } }) => {
      state.isHeaderBgGone = status;
    },
    updateHomeTextGone: (state, { payload: { status } }) => {
      state.isHomeTextGone = status;
    },
    updateDetailBannerRestShow: (state, { payload: { status } }) => {
      state.isBannerRestShown = status;
    },
    setAllImagesLoadingDone: (state, { payload: { status } }) => {
      state.isDetailHeaderAndContentShown = status;
    },
    updateDetaiLoadingStatus: (state, { payload: { status } }) => {
      state.isImagesLoading = status;
    },
    updateImageLoadedCount: (state, { payload: { name } }) => {
      state.allBannerImageLoadedCount.push(name);
    },
    resetImageLoadedCount: (state) => {
      state.allBannerImageLoadedCount = [];
    },
    updateMainPageStatus: (state, { payload: { status } }) => {
      state.isMainLoaded = status;
    },
  },
});

export const {
  resetSlice,
  updateMainPageIndex,
  updateBioPageOpenStatus,
  updateSelectedSectionIndex,
  updateHeaderBgGone,
  updateHomeTextGone,
  updateDetailBannerRestShow,
  setAllImagesLoadingDone,
  updateDetaiLoadingStatus,
  updateImageLoadedCount,
  updateMainPageStatus,
  resetImageLoadedCount,
} = animationsSlice.actions;
export default animationsSlice.reducer;
