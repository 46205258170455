import styled from '@emotion/styled';
import React from 'react';

import Names from '../../components/Names/Names';
import sections from '../../constants/sections';

export default function Texts({ delayClass }) {
  return (
    <TextContainer id='mainpage-texts' className={delayClass}>
      <Names title='' sectionIndex={sections.Home.index} />
      <Names
        title={sections.FifthPosition.title}
        sectionIndex={sections.FifthPosition.index}
      />
      <Names
        title={sections.LorealContentPro.title}
        sectionIndex={sections.LorealContentPro.index}
      />
      <Names
        title={sections.SpitzGermany.title}
        sectionIndex={sections.SpitzGermany.index}
      />
      <Names title={sections.Wamo.title} sectionIndex={sections.Wamo.index} />
      <Names title={sections.Lsa.title} sectionIndex={sections.Lsa.index} />
      <Names
        title={sections.SelfApp.title}
        sectionIndex={sections.SelfApp.index}
      />
      <Names
        title={sections.BunkerCustoms.title}
        sectionIndex={sections.BunkerCustoms.index}
      />
      <Names
        title={sections.BNCFFShowcase.title}
        sectionIndex={sections.BNCFFShowcase.index}
      />
      <Names
        title={sections.AlawwalBank.title}
        sectionIndex={sections.AlawwalBank.index}
      />
    </TextContainer>
  );
}

const TextContainer = styled.div({
  position: 'absolute',
  width: '100%',
  height: 240,
  pointerEvents: 'none',
  // overflow: 'hidden',
  top: 0,
  left: 95,
  bottom: 0,
  margin: 'auto',
  zIndex: 900,
  transform: 'translate3d(0,30px,0)',
  '&.down .active': {
    transitionDelay: '550ms!important',
    transition: 'transform 400ms ease-out,opacity 400ms ease-out',
  },
  '& > div': {
    height: 240,
  },
  '@media (max-width:800px)': {
    left: 20,
    transform: 'translate3d(0,44px,0)',
  },
  '@media (max-width:600px)': {
    height: 120,
    left: 28,
    transform: 'translate3d(0,37px,0)',
    '& > div': {
      height: 120,
    },
  },
});
