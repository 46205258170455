import React, { useEffect, useRef, useState } from 'react';

import Text from '../Text/Text';

import { C } from './ContentHeader.styles';

export default function ContentHeader({
  subtitle,
  title,
  isShown,
  setMaxHeight,
  ...props
}) {
  const [maxWidth, setMaxWidth] = useState(0);
  const [pWidth, setPWidth] = useState(0);
  const [hWidth, setHWidth] = useState(0);

  const contentRef = useRef(null);

  useEffect(() => {
    const w = hWidth > pWidth ? hWidth : pWidth;
    setMaxWidth(w);
    if (typeof setMaxHeight === 'function') {
      setMaxHeight(contentRef.current.clientHeight);
    }
  }, [isShown]);

  return (
    <C.Container
      className={isShown ? 'active' : ''}
      ref={contentRef}
      maxWidth={maxWidth}
      onTransitionEnd={() => setMaxWidth('100vw')}
      {...props}
    >
      <div>
        <Text size='sm' calcWidth={(e) => setPWidth(e)}>
          {subtitle}
        </Text>
        <br />
        <Text size='lm' as='h2' font='bold' calcWidth={(e) => setHWidth(e)}>
          {title}
        </Text>
      </div>
    </C.Container>
  );
}
