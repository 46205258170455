import React from 'react';
import { C } from './Content.styles';

export default function Content({
  children,
  backgroundColor,
  isShown,
  isFull,
  mobileFull,
  isBanner = false,
  marginTop = false,
  ...props
}) {
  return (
    <C.Container
      className={isShown ? 'shown' : ''}
      backgroundColor={backgroundColor}
      isFull={isFull}
      mobileFull={mobileFull}
      isBanner={isBanner}
      marginTop={marginTop}
      {...props}
    >
      {children}
    </C.Container>
  );
}
