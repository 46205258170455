import styled from "@emotion/styled"
export const F = {}

F.FullImage = styled.div({
  width: "100%",
  display: "flex",
  "& img": {
    width: "100%",
    height: "auto",
    display: "block",
  },
})
