import { removeClass } from './evensts';

export const resetAnimations = (par) => {
  const anims = document.querySelectorAll(
    `${par} .animated,${par} .scroll-animation-elem.childs > *`
  );

  for (let index = 0; index < anims.length; index++) {
    anims[index].setAttribute('style', '');
    removeClass(anims[index], 'animated');
  }
};
