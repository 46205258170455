import styled from '@emotion/styled';

export const C = {};
C.Container = styled.div(
  ({ maxHeight, backgroundColor, mobileFull, isFull }) => {
    return {
      width: '100%',
      padding: isFull ? 0 : '0 60px',
      overflow: 'hidden',

      backgroundColor: backgroundColor || 'transparent',

      '& .image-content > img': {
        width: '100%',
      },
      '@media (max-width:800px)': {
        padding: isFull ? 0 : mobileFull ? 0 : '0 30px',
      },
    };
  }
);
