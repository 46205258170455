import React, { createRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { S } from './Section.styles';

import sections from '../../constants/sections';
import Banner from '../Banner';

import Loading from '../Loading/Loading';

import {
  addClass,
  hasClass,
  isBrowser,
  removeClass,
} from '../../utils/evensts';
import { useState } from 'react';

import { scrollAnimation } from '../../utils/scrollAnimations';
import ArrowDown from '../../assets/ArrowDown';
import { useWindowDimensions } from '../../utils/window';
import { _spanToText } from '../../utils/textConvertor';
import {
  selectIsAllSectionGoneButSelected,
  selectIsDetailHeaderAndContentShown,
  selectIsImagesLoading,
  selectMainPageActiveIndex,
  selectSelectedSectionIndex,
} from '../../features/selectors';

let scrollSectionTop = 0;

const Section = ({
  sectionName,
  className,
  isHome,
  loadedImageCount,
  itemCount,
  children,
  ...props
}) => {
  const { width, height } = useWindowDimensions();
  const mainPageActiveIndex = useSelector(selectMainPageActiveIndex);
  const selectedSectionIndex = useSelector(selectSelectedSectionIndex);
  const isAllSectionGoneButSelected = useSelector(
    selectIsAllSectionGoneButSelected
  );
  const isDetailHeaderAndContentShown = useSelector(
    selectIsDetailHeaderAndContentShown
  );
  const isImagesLoading = useSelector(selectIsImagesLoading);
  const sectionRef = createRef();
  const {
    index: sectionIndex,
    arrowColor,
    mobileArrowColor,
  } = sections[sectionName];

  const [scrollClass] = useState('');
  const [scrollY, setScrollY] = useState(0);
  const activeClass = mainPageActiveIndex === sectionIndex ? 'active' : '';
  const prevClass = mainPageActiveIndex > sectionIndex ? 'prev' : '';
  const nextClass = mainPageActiveIndex + 1 === sectionIndex ? 'next' : '';
  const sectionSelectedClass =
    selectedSectionIndex === sectionIndex ? 'selected' : '';
  const goneClass =
    selectedSectionIndex !== sectionIndex &&
    isAllSectionGoneButSelected &&
    mainPageActiveIndex !== sectionIndex
      ? 'gone'
      : '';

  const resultClass =
    `section ${activeClass} ${prevClass} ${nextClass} ${sectionSelectedClass} ${goneClass} 
    ${isDetailHeaderAndContentShown ? 'loaded' : ''} ${
      className ? className : ''
    }`
      .replace(/\s+/g, ' ')
      .trim();

  useEffect(() => {
    if (selectedSectionIndex > -1) _spanToText(sectionRef.current);
    removeClass('body', 'scroll-half');
  }, [width]);

  function _handleScroll(event) {
    handleAnimation(event);
    handleHeaderColor(event);

    var nextElem = document.getElementById('container__next-elem');

    if (typeof nextElem !== 'undefined') {
      const nextHeight = sectionRef.current.offsetWidth < 800 ? 210 : 410;
      const diff =
        sectionRef.current.scrollHeight -
        sectionRef.current.scrollTop -
        sectionRef.current.clientHeight;

      var opacityDiv = document.querySelectorAll(
        '#container__next-elem > div'
      )[1];

      if (sectionRef.current.scrollTop > height) {
        addClass('body', 'scroll-half');
      } else {
        removeClass('body', 'scroll-half');
      }

      if (typeof opacityDiv !== 'undefined') {
        document.querySelectorAll(
          '#container__next-elem > div'
        )[1].style.opacity = (100 * diff) / (nextHeight * 100);
      }
    }

    if (typeof event.deltaY === 'undefined') {
      let result = event.target.scrollTop < scrollSectionTop;
      if (scrollSectionTop < 200) result = true;
      _handleSwipe(result);
      scrollSectionTop = event.target.scrollTop;
    } else {
      _handleSwipe(event.deltaY < 0, event);
    }
  }

  function handleAnimation(event) {
    if (!isBrowser) return;
    if (window.innerWidth > 800) {
      const scrollTop = event.target.scrollTop;
      const banner = document.querySelectorAll(
        '.section.active .section-banner'
      )[0];
      if (typeof banner !== 'undefined') {
        const bannerTop = banner.getBoundingClientRect().top;
        if (bannerTop > banner.offsetHeight * -0.1) {
          const scrollRate = (scrollTop * 100) / window.innerHeight;
          // banner.style.transform = 'translate3d(0,' + scrollRate / 5 + '%,0)';
          // banner.parentNode.style.marginBottom = scrollRate / 5 + '%';
          banner.style.marginTop = scrollRate / 7 + '%';
        }
      }
    }

    scrollAnimation(sectionRef.current.scrollTop);
  }

  function _handleSwipe(e, event) {
    if (selectedSectionIndex < 0 && !isDetailHeaderAndContentShown) return;
    if (sectionRef.current.scrollTop > 0)
      addClass('.section.active.selected', 'scrolled');
    else removeClass('.section.active.selected', 'scrolled');
    if (!e) addClass('header', 'header-up');
    else removeClass('header', 'header-up');
  }

  function handleHeaderColor(event) {
    const header = document.getElementsByTagName('HEADER')[0];
    if (selectedSectionIndex < 0 && !isDetailHeaderAndContentShown) {
      removeClass(header, 'light');
    } else {
      const points = document.querySelectorAll(
        '.section.active .header-scroll-point'
      );
      for (let index = 0; index < points.length; index++) {
        const element = points[index];
        const elementTop = element.getBoundingClientRect().top;

        if (scrollY < event.target.scrollTop) {
          if (elementTop < 60) {
            if (hasClass(element, 'header-light')) addClass(header, 'light');
            else removeClass(header, 'light');
          }
        } else {
          if ((elementTop > 60) & (elementTop < window.innerHeight)) {
            if (hasClass(element, 'header-light')) removeClass(header, 'light');
            else addClass(header, 'light');
          }
        }

        setScrollY(event.target.scrollTop);
      }
    }
  }

  return (
    <S.Section
      ref={sectionRef}
      className={`${resultClass} ${scrollClass}`}
      onScroll={(e) => _handleScroll(e)}
      {...props}
    >
      {isHome ? (
        <>{children}</>
      ) : (
        <>
          {isImagesLoading && (
            <Loading
              itemCount={itemCount}
              loadedImageCount={loadedImageCount}
              isContentLoader
            />
          )}
          <Banner sectionName={sectionName}>
            <div className={`banner-arrow-down`}>
              <ArrowDown color={width < 800 ? mobileArrowColor : arrowColor} />
            </div>
            {children}
          </Banner>
        </>
      )}
    </S.Section>
  );
};

export default Section;
