import React from "react"

import theme from "../../theme"
import { B } from "./Button.styles"

export default function Button({
  children,
  background = theme.colors.flavorWhite,
  ...others
}) {
  return (
    <B.Button type="button" background={background} {...others}>
      {children}
    </B.Button>
  )
}
