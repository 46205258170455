import * as React from 'react';

const ArrowDown = ({ color = '#000', ...props }) => (
  <svg
    width={16}
    height={46}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.812 39.89a.5.5 0 0 0-.624-.78L8.5 44.459V.5a.5.5 0 0 0-1 0v43.96L.812 39.11a.5.5 0 0 0-.624.78l7.498 6a.5.5 0 0 0 .625 0l7.501-6Z'
      fill={color}
    />
  </svg>
);

export default ArrowDown;
