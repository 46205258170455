import styled from '@emotion/styled';
import { Link } from 'gatsby';
import theme from '../../theme';

export const F = {};

F.Footer = styled.footer({
  width: '100%',
  background: theme.colors.white,
  padding: 100,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',

  '& a': {
    pointerEvents: 'all!important',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& div': {
    display: 'flex',
    '& > div': {
      marginRight: 20,
    },
  },
  '& > div:first-of-type': {
    marginBottom: 40,
  },
  '& p + img': {
    display: 'none',
  },

  '@media (max-width:600px)': {
    padding: 0,
    '& a': {
      padding: 40,
    },
    '& > div:first-of-type': {
      display: 'none',
    },
    '& p + img': {
      display: 'inline',
    },
    '& button, & > div': {
      width: '100%',
    },
    '& button': {
      justifyContent: 'space-between',
    },
  },
});

F.FakeNext = styled(Link)`
  height: 410px !important;
  display: block;
  width: 100%;
  pointer-events: all !important;
  opacity: 0;

  @media screen and (max-width: 800px) {
    height: 210px !important;
  }
`;
