import React, { useState } from 'react';

import Section from '../../components/Section/Section';
import Description from '../../components/Description';

import sections from '../../constants/sections';
import Content from '../../components/Content';
import Picture from '../../components/Picture';
import Footer from '../../components/Footer/Footer';

import FullImage from '../../components/FullImage';

import { B } from './BunkerCustoms.styles';

import fontImage from '../../assets/images/bunker/bunker-fonts.jpg';
import fontMobileImage from '../../assets/images/bunker/bunker-fonts-mobile.jpg';

import web1Image from '../../assets/images/bunker/bunker-web-1.jpg';
import web1MobileImage from '../../assets/images/bunker/bunker-web-1-mobile.jpg';
import web2Image from '../../assets/images/bunker/bunker-web-2.jpg';
import web2MobileImage from '../../assets/images/bunker/bunker-web-2-mobile.jpg';
import web3Image from '../../assets/images/bunker/bunker-web-3.jpg';
import web3MobileImage from '../../assets/images/bunker/bunker-web-3-mobile.jpg';
import page1Image from '../../assets/images/bunker/bunker-pages-1.jpg';
import page1MobileImage from '../../assets/images/bunker/bunker-pages-1-mobile.jpg';
import page2Image from '../../assets/images/bunker/bunker-pages-2.jpg';
import page2MobileImage from '../../assets/images/bunker/bunker-pages-2-mobile.jpg';
import phone1Image from '../../assets/images/bunker/bunker-phones-1.jpg';
import phone1MobileImage from '../../assets/images/bunker/bunker-phones-1-mobile.jpg';
import phone2Image from '../../assets/images/bunker/bunker-phones-2.jpg';
import phone2MobileImage from '../../assets/images/bunker/bunker-phones-2-mobile.jpg';
import footerImage from '../../assets/images/bunker/bunker-footer.jpg';
import footerMobileImage from '../../assets/images/bunker/bunker-footer-mobile.jpg';

import desktopVideo from '../../assets/videos/bunker-desktop.mp4';
import mobileVideo from '../../assets/videos/bunker_mobile.mp4';

import Video from '../../components/Video';
import { useWindowDimensions } from '../../utils/window';

const BunkerCustoms = () => {
  const name = 'BunkerCustoms';
  const { description, mainColor } = sections[name];
  const [loadedImageCount, setLoadedImageCount] = useState(0);
  const { width } = useWindowDimensions();
  const itemCount = width < 768 ? 9 : 11;

  return (
    <Section
      sectionName={name}
      itemCount={itemCount}
      loadedImageCount={loadedImageCount}
    >
      <Description desc={description} />
      <Content isShown={true}>
        <B.FontContent>
          <Picture
            mobile={fontMobileImage}
            desktop={fontImage}
            alt='Bunker web site fonts'
            onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
            data-top='1.5'
            className='scroll-animation-elem'
            data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
          />
        </B.FontContent>
      </Content>
      <Content
        backgroundColor='#060506'
        isShown={true}
        isFull={true}
        // className='scroll-animation-elem'
        // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
      >
        <Video
          isFull
          video={desktopVideo}
          mobileVideo={desktopVideo}
          backgroundColor='#bcc2c7'
          onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
        />
      </Content>
      <Content
        isFull
        backgroundColor='#1c1d22'
        className='header-scroll-point header-light'
      >
        <div>
          <FullImage
            mainColor={mainColor}
            image={web1Image}
            mobileImage={web1MobileImage}
            alt='Bunker web page'
            loadCallBack={() =>
              setLoadedImageCount((prevcount) => prevcount + 1)
            }
          />
          <FullImage
            mainColor={mainColor}
            image={web2Image}
            mobileImage={web2MobileImage}
            alt='Bunker web page'
            loadCallBack={() =>
              setLoadedImageCount((prevcount) => prevcount + 1)
            }
          />
          <FullImage
            mainColor={mainColor}
            image={web3Image}
            mobileImage={web3MobileImage}
            alt='Bunker web page'
            loadCallBack={() =>
              setLoadedImageCount((prevcount) => prevcount + 1)
            }
          />
        </div>
      </Content>
      <Content isFull backgroundColor='#bbc3c6' className='header-scroll-point'>
        <div
        // className='scroll-animation-elem'
        // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        >
          <FullImage
            mainColor={mainColor}
            image={page1Image}
            mobileImage={page1MobileImage}
            alt='Bunker web pages'
            loadCallBack={() =>
              setLoadedImageCount((prevcount) => prevcount + 1)
            }
          />
          <FullImage
            mainColor={mainColor}
            image={page2Image}
            mobileImage={page2MobileImage}
            alt='Bunker web pages'
            loadCallBack={() =>
              setLoadedImageCount((prevcount) => prevcount + 1)
            }
          />
        </div>
      </Content>
      {/* <Content isFull backgroundColor='#727981'>
        <FullImage
          mainColor={mainColor}
          image={phoneImage}
          mobileImage={phoneMobileImage}
          alt='Bunker app'
          loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
          // className='scroll-animation-elem'
          // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        />
      </Content> */}
      <Content
        backgroundColor='#060506'
        isShown={true}
        isFull={true}
        // className='scroll-animation-elem'
        // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
      >
        <Video
          isFull
          video={mobileVideo}
          mobileVideo={mobileVideo}
          backgroundColor='#737882'
          onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
        />
      </Content>
      <Content isFull backgroundColor='#abb4bb'>
        <div
        // className='scroll-animation-elem'
        // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        >
          <FullImage
            mainColor={mainColor}
            image={phone1Image}
            mobileImage={phone1MobileImage}
            alt='Bunker app screens'
            loadCallBack={() =>
              setLoadedImageCount((prevcount) => prevcount + 1)
            }
          />
          <FullImage
            mainColor={mainColor}
            image={phone2Image}
            mobileImage={phone2MobileImage}
            alt='Bunker app screens'
            loadCallBack={() =>
              setLoadedImageCount((prevcount) => prevcount + 1)
            }
          />
        </div>
        <FullImage
          mainColor={mainColor}
          image={footerImage}
          mobileImage={footerMobileImage}
          alt='Bunker app screen'
          loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
          // className='scroll-animation-elem'
          // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        />
      </Content>

      <Footer />
    </Section>
  );
};
export default BunkerCustoms;
