import anime from 'animejs';
import { addClass, hasClass, removeClass } from './evensts';
// import { _spanToText } from './textConvertor';

export const scrollAnimation = () => {
  const array = document.querySelectorAll('.scroll-animation-elem');

  for (let index = 0; index < array.length; index++) {
    const element = array[index];

    var viewportOffset = hasClass(element, 'scroll-parent')
      ? element.parentNode.getBoundingClientRect()
      : element.getBoundingClientRect();
    var top = viewportOffset.top;

    const triggerPoint = element.getAttribute('data-top')
      ? parseFloat(element.getAttribute('data-top'))
      : 0.7;

    const distance = top < 0 + window.innerHeight * triggerPoint;

    const reverseDistance = top > 0 + window.innerHeight * triggerPoint;

    if (distance && !hasClass(element, 'animated')) {
      animateElement(element);
    } else if (
      hasClass(element, 'animated') &&
      hasClass(element, 'both') &&
      reverseDistance
    ) {
      animateElement(element, true);
    }
  }
};

const animateElement = (element, reversed) => {
  if (reversed) removeClass(element, 'animated');
  else addClass(element, 'animated');

  const animateText = element.getAttribute(
    reversed ? 'data-reverse-animate' : 'data-animate'
  );
  const animationData = JSON.parse(animateText);
  const hasChilds = hasClass(element, 'childs');
  let elems = hasChilds ? element.children : element;
  const isContentText = hasClass(element, 'content-text');
  const isWrapperText = element.getAttribute('data-text', 'wrapper');
  if (isContentText) elems = element.querySelectorAll(' *,p span');
  const delay = element.getAttribute('data-delay')
    ? parseInt(element.getAttribute('data-delay'))
    : 50;

  const animData = {
    targets: elems,
    easing: 'easeOutCubic',
    ...animationData,
    ...(hasChilds && {
      delay: function (el, i, l) {
        return 100 + i * delay;
      },
    }),
    ...(isWrapperText && {
      complete: function (el) {
        // _spanToText(el.animatables[0].target.parentNode.parentNode);
      },
    }),
  };

  anime(animData);
};
