import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
export const H = {};

const textCome = keyframes`
 0%{transform:translate3d(0,60%,0)}
 100%{transform:translate3d(0,0%,0);opacity:1}
`;

const textNextCome = keyframes`
 0%{transform:translate3d(0,130%,0)}
 100%{transform:translate3d(0,0%,0);opacity:1}
`;

const textComeMobile = keyframes`
 0%{transform:translate3d(0,50%,0)}
 100%{transform:translate3d(0,0%,0);opacity:1}
`;

const textNextComeMobile = keyframes`
 0%{transform:translate3d(0,80%,0)}
 100%{transform:translate3d(0,0%,0);opacity:1}
`;

const h1Come = keyframes`
 0%{transform:translate3d(50%,2vh,0);opacity:0}
 100%{transform:translate3d(0,2vh,0);opacity:1}
`;

H.Container = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  position: 'relative',
  background: '#F8F7F5',
  '& *': {
    '-webkit-transform-style': 'preserve-3d',
    '-webkit-backface-visibility': 'hidden',
  },

  '& #uc-home__mail': {
    opacity: 0,
    transform: 'translate3d(60%,0,0)',
    marginBottom: '0.7rem',
    transition:
      'transform 0.5s cubic-bezier(.14,.35,.51,.95), opacity 0.5s cubic-bezier(.14,.35,.51,.95)',
  },
  'body.loaded & #uc-home__mail': {
    transform: 'translate3d(0,0,0)',
    opacity: 1,
    pointerEvents: 'all',
  },
  'body.loaded .prev & #uc-home__mail, body.loaded.bio-page & #uc-home__mail': {
    opacity: 0,
  },
  'body.loaded.page-setted .home-page & #uc-home__mail': {
    transitionDelay: '600ms',
  },
  '& h1': {
    display: 'none',
    '& .header-line': {
      display: 'inline-block!important',
      verticalAlign: 'middle',
      height: 1,
      width: 24,
      background: 'black',
      marginTop: '0!important',
    },
  },
  '& p': {
    lineHeight: 0.9,
  },
  '& span': {
    display: 'block',
    lineHeight: '80%',
    '-webkit-transform-style': 'preserve-3d',
    '-webkit-backface-visibility': 'hidden',
    transition:
      'transform 0.5s cubic-bezier(.14,.35,.51,.95),opacity 0.5s cubic-bezier(.14,.35,.51,.95)',

    'body.animate-home &': {
      animationDelay: '500ms!important',
    },
    'body.loaded:not(.page-setted) &, body.animate-home &': {
      transition: 'none',
      animation: `${textCome} 400ms ease-out forwards`,
    },
    'body.loaded .prev & ,body.loaded.bio-page &': {
      transform: 'translate3d(0,-100%,0)',
      opacity: 0,
    },
  },
  '& span:last-child': {
    display: 'block',
    marginTop: '0.145em',
    marginBottom: '0.465rem',

    'body.loaded:not(.page-setted) &,body.animate-home &': {
      transition: 'none',
      animation: `${textNextCome} 0.5s cubic-bezier(.14,.35,.51,.95) forwards`,
    },
    'body.loaded .prev &, body.loaded.bio-page &': {
      transform: 'translate3d(0,-70%,0)',
      opacity: 0,
    },
  },
  '.home-page & span': {
    transitionDelay: '300ms',
  },
  '& .mobile-arrow-down': { display: 'none' },

  '@media (max-width:1024px)': {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingBottom: 79,
    '& span': {
      transition:
        'transform 0.7s cubic-bezier(.14,.35,.51,.95),opacity 0.7s cubic-bezier(.14,.35,.51,.95)',
    },
    '& h1': {
      display: 'block',
      lineHeight: '21px',
      letterSpacing: '-0.02em',
      transform: 'translate3d(0,2vh,0)',
      transition:
        'transform 0.5s cubic-bezier(.14,.35,.51,.95),opacity,0.5s cubic-bezier(.14,.35,.51,.95)',

      'body.loaded &': {
        animation: `${h1Come} 700ms ease-in-out`,
      },
      'body.loaded .prev &,body.loaded.bio-page &': {
        transform: 'translate3d(0,-150px,0)',
        opacity: 0,
      },
    },
    '&  p': {
      transform: 'translate3d(0,0,0)',
      marginTop: 40,
    },
    '& a': {
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
    '& > p > span': {
      transitionDelay: '0ms!important',
      'body.loaded:not(.page-setted) &': {
        animation: `${textComeMobile} 0.5s cubic-bezier(.14,.35,.51,.95) forwards`,
      },
      'body.loaded .prev &,body.loaded.bio-page &': {
        transform: 'translate3d(0,-150px,0)',
      },
    },
    '& > p > span:last-child': {
      ':not(.detail-page) body.loaded:not(.page-setted) &': {
        animation: `${textNextComeMobile} 0.5s cubic-bezier(.14,.35,.51,.95) forwards`,
      },
      'body.loaded .prev &,body.loaded.bio-page &': {
        transform: 'translate3d(0,-150px,0)',
      },
    },
    '& #uc-home__mail': {
      transform: 'translate3d(0,100%,0)',
      transition:
        'transform 0.7s cubic-bezier(.14,.35,.51,.95), opacity 0.7s cubic-bezier(.14,.35,.51,.95)',
    },
  },
  '@media (max-width:768px)': {
    '& > p': {
      fontSize: '20vw',
      lineHeight: 1,
    },
    '& a': {
      bottom: 30,
    },
  },
  '@media (max-width:600px)': {
    paddingBottom: 68,
    '& span': {
      lineHeight: 1,
      fontSize: '20.2vw',
    },
    '& #uc-home__mail': {
      marginBottom: 0,
    },
    '& span:last-child': { marginTop: 0 },
    '& .mobile-arrow-down': {
      display: 'block',
      width: 15,
      position: 'absolute',
      bottom: 30,
      right: 0,
      opacity: 0,
      marginRight: 30,
      transform: 'translate3d(0,-100%,0)',
      transition:
        'transform 0.5s  ease-in,opacity 0.5s cubic-bezier(.14,.35,.51,.95)',
      'body.loaded &': {
        opacity: 1,
        transform: 'translate3d(0,0,0)',
      },
      'body.loaded .prev &,body.loaded.bio-page &': {
        opacity: 0,
      },
    },
    '& p br': { display: 'none' },
  },
});
