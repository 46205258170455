import React from 'react';
import { B } from './BannerContent.styles';

export default function BannerContent({
  children,
  backgroundColor,
  isShown,
  isFull,
  mobileFull,
  maxHeight = 351,
  isBanner = false,
  ...props
}) {
  return (
    <B.Container
      className={isShown ? 'shown' : ''}
      backgroundColor={backgroundColor}
      maxHeight={maxHeight}
      isFull={isFull}
      mobileFull={mobileFull}
      isBanner={isBanner}
      {...props}
    >
      {children}
    </B.Container>
  );
}
