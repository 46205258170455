import React from 'react';

import Link from '../../components/Link/Link';
import Section from '../../components/Section/Section';
import Text from '../../components/Text/Text';

import { H } from './Home.styles';

import arrowDown from '../../assets/arrow-down.svg';

const Home = () => {
  return (
    <Section sectionName='Home' className='full' isHome>
      <H.Container>
        <Text as='h1'>
          Art Director & <br />
          Lead Designer
          <br />
          Portfolio <span className='header-line'></span> 2022
        </Text>
        <Text>
          <Text as='span' size='xxl' font='bold'>
            Ufuk
          </Text>
          <Text as='span' size='xxl' font='bold'>
            Cetincan
          </Text>
        </Text>
        <Link
          href='mailto:hello@ufuk.work'
          isExternal={true}
          id='uc-home__mail'
        >
          <Text underline>hello@ufuk.work</Text>
        </Link>
        <img src={arrowDown} alt='arrow down' className='mobile-arrow-down' />
      </H.Container>
    </Section>
  );
};

export default Home;
