import React, { useRef, useEffect, useState } from 'react';

import { useWindowDimensions } from '../../utils/window';

import { V } from './Video.styles';

import PLAYICON from '../../assets/images/play_icon.svg';

export default function Video({
  image,
  mobileImage,
  maxImageWidth,
  isFull,
  backgroundColor,
  video,
  mobileVideo,
  onLoad,
  ...props
}) {
  const { width } = useWindowDimensions();
  const videoRef = useRef();
  const handleLoad = (e) => setVideoLoad(e);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  const setVideoLoad = (e) => {
    onLoad(e);
  };

  useEffect(() => {
    if (videoRef.current && videoRef.current.complete) {
      setVideoLoad();
    }
  }, []);

  const _handleOnWaiting = () => setVideoLoading(true);
  const _handleOnPlaying = () => setVideoLoading(false);

  const _handleVideoButton = () => {
    if (videoPlaying) videoRef.current.pause();
    else videoRef.current.play();

    setVideoPlaying((e) => !e);
  };

  return (
    <V.Container backgroundColor={backgroundColor} {...props}>
      <V.ImageContainer maxImageWidth={maxImageWidth} isFull={isFull}>
        <V.VideoContainer>
          {mobileVideo && width < 600 ? (
            <>
              <div>
                <video
                  playsInline
                  muted
                  loop
                  ref={videoRef}
                  preload='auto'
                  onWaiting={_handleOnWaiting}
                  onPlaying={_handleOnPlaying}
                >
                  <source src={`${mobileVideo}#t=0.1`} type='video/mp4' />
                </video>
              </div>
              <V.Play onClick={_handleVideoButton} $active={videoPlaying}>
                <img src={PLAYICON} />
              </V.Play>
              {videoLoading && <V.Waiter />}
            </>
          ) : (
            <video
              playsInline
              autoPlay
              muted
              loop
              ref={videoRef}
              onLoadedData={(e) => handleLoad(e)}
            >
              <source src={video} type='video/mp4' />
            </video>
          )}
        </V.VideoContainer>
      </V.ImageContainer>
    </V.Container>
  );
}
