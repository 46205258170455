import React from 'react';
import { L } from './Link.styles';

export default function Link({
  to,
  isExternal,
  target = '_blank',

  children,
  ...others
}) {
  const Component = isExternal ? L.ExternalLink : L.Link;
  return (
    <Component to={to} {...(isExternal && { target })} {...others}>
      {children}
    </Component>
  );
}
