import React, { useEffect } from 'react';
import { wrapTextToSpan, _spanToText } from '../../utils/textConvertor';
import { v4 as uuidv4 } from 'uuid';

import { T } from './ContentText.styles';
import Text from '../Text/Text';
import { C } from '../../containers/Containers.styles';

export const ContentText = ({ title, notHalf, children, ...props }) => {
  const id = uuidv4();

  useEffect(() => {
    const desc = document.getElementById(id);
    setTimeout(() => {
      wrapTextToSpan(desc);
    }, 1500);

    return () => {
      _spanToText(desc);
    };
  }, []);

  return (
    <>
      {notHalf ? (
        <T.Text
          id={id}
          size='mdx'
          className='scroll-animation-elem childs text-half'
          data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        >
          {children}
        </T.Text>
      ) : (
        <C.Half
          className='scroll-animation-elem childs text-half content-text'
          data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
          {...props}
        >
          {title && (
            <Text as='h4' size='sx' font='bold'>
              {title}
            </Text>
          )}
          <T.Text id={id} size='mdx'>
            {children}
          </T.Text>
        </C.Half>
      )}
    </>
  );
};
