import { addClass, hasClass, removeClass } from '../utils/evensts';
import {
  _homeToDetail,
  _updateBioPageOpen,
  _updateHeaderBgGon,
  _updateMainPageIndex,
  _updateSectionIndex,
  _returnUrlSectionIndex,
  _handleHomeText,
  _handleDetailRest,
  _handleDetailLoading,
  _detailToWork,
  _detailToHome,
  _detailtoPushWork,
  _bioToWork,
  _bioToHome,
  _mainToBio,
  _handleMainPageScroll,
  _poptoDetailFromBio,
} from './helper';

const checkFreshpage = (pageIndex) => {
  const index = _returnUrlSectionIndex();

  if (window.location.hash === '#work') {
    openWorkDefault();
    return;
  } else if (index === 10) {
    openBioFromZero();
    return;
  } else if (pageIndex === -1 && index === 0) {
    openHomeFromZero();
    return;
  } else {
    openDetailFromZero();
  }
};

function openHomeFromZero() {
  addClass('header', 'pre');
  addClass(document.body, 'fresh-home');
  setTimeout(() => {
    addClass(document.body, 'page-setted');
    removeClass(document.getElementsByTagName('HEADER'), 'pre');
  }, 500);
  _updateMainPageIndex(0);
}

function openBioFromZero() {
  addClass(document.body, 'fresh-home page-setted');
  _updateBioPageOpen(true);
}

function pushToHomeFrombio() {
  addClass(document.body, 'fresh-home page-setted');
  _bioToHome();
}

function openDetailFromZero() {
  removeClass('#content-container > .section.first-section', 'bottom-to-top');
  document
    .querySelectorAll(
      '#content-container > .section.first-section > div:nth-of-type(2)'
    )[0]
    .setAttribute('style', '');
  addClass(document.body, 'page-setted');

  const index = _returnUrlSectionIndex();

  _updateMainPageIndex(index);
  _updateSectionIndex(index);
  _updateHeaderBgGon(true);
  _handleDetailRest(true);
  _handleDetailLoading(true);
  _handleHomeText(true);
}

function openWorkDefault() {
  _handleHomeText(false);
  addClass(document.body, 'fresh-home page-setted');

  _updateMainPageIndex(1);
}

function openBioPageFromZero() {
  _updateBioPageOpen(true);
}

function popToHome() {
  if (hasClass(document.getElementById('content-body'), 'slided')) {
    _bioToHome();
  } else {
    _detailToHome();
  }
}

function popToWork() {
  if (hasClass(document.querySelector('header'), 'detail')) {
    _detailToWork();
    document.querySelectorAll('.section.active')[0].scrollTop = 0;
  } else {
    _handleMainPageScroll(1);
  }
}

function popToSection() {
  if (hasClass(document.getElementById('content-body'), 'slided')) {
    popToWorkFromBio();
  } else popToWork();
}

function pushToHome() {
  _detailToHome();
}

function pushToWork() {
  _detailtoPushWork();
}

function pushToSection() {
  _homeToDetail();
}

function pushBio() {
  _mainToBio();
}

function popBio() {
  _updateBioPageOpen(true);
}

function detailPageIsReady() {}

function pusheToAnotherSection(url, index) {}

function pushToWorkFromBio() {
  _bioToWork();
}

function popToWorkFromBio() {
  _bioToWork();
}

function popDetailFromBio() {
  _poptoDetailFromBio();
}

export {
  openDetailFromZero,
  popToHome,
  popToSection,
  pushToHome,
  pushToSection,
  checkFreshpage,
  detailPageIsReady,
  openBioPageFromZero,
  pushToWork,
  popToWork,
  pusheToAnotherSection,
  pushToWorkFromBio,
  pushToHomeFrombio,
  pushBio,
  popBio,
  popToWorkFromBio,
  openWorkDefault,
  popDetailFromBio,
};
