import styled from "@emotion/styled"
import theme from "../../theme"

export const B = {}

B.Button = styled.button(({ background }) => {
  return {
    height: 60,
    width: "100%",
    backgroundColor: background,
    outline: "none",
    border: "none",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 20,
    lineHeight: "24px",
    letterSpacing: "-0.01em",
    color: theme.colors.black,
    "& img": {
      marginLeft: 31,
    },
  }
})
