import React from 'react';
import { useSelector } from 'react-redux';

// import { closeSelectedPage } from "../../helpers/sectionHelpers"

import Link from '../Link/Link';
import Text from '../Text/Text';

import { H } from './Header.styles';

import ucLogo from '../../assets/ufuk_cetincan.svg';
import ucLogoMobile from '../../assets/cetincan-logo.svg';
import ucLogoWhite from '../../assets/ufuk_cetincan-white.svg';
import ucLogoMobileWhite from '../../assets/cetincan-logo-white.svg';

import Picture from '../Picture/Picture';
import {
  selectMainPageActiveIndex,
  selectIsHeaderBgGone,
  selectIsBioPageOpen,
} from '../../features/selectors';

export default function Header() {
  const mainPageActiveIndex = useSelector(selectMainPageActiveIndex);
  const isHeaderBgGone = useSelector(selectIsHeaderBgGone);
  const isBioPageOpen = useSelector(selectIsBioPageOpen);

  const isHomeClass = mainPageActiveIndex === 0 ? 'home' : '';
  const isSectionSelected = isHeaderBgGone ? 'detail' : '';
  const isBioSelected = isBioPageOpen ? 'bio' : '';
  const isWork = mainPageActiveIndex > 0 && !isHeaderBgGone ? 'work' : '';

  return (
    <H.Header
      className={`${isWork} ${isSectionSelected} ${isHomeClass} ${isBioSelected} `}
    >
      <Link to='/'>
        <Text as='h1'>
          Art Director <br />
          & Lead Designer
          <br />
          Portfolio <span className='header-line'></span> 2021
        </Text>
        <Picture
          mobile={ucLogoMobile}
          desktop={ucLogo}
          alt='Ufuk Çetincan'
          className='header-text-ufuk'
          pointkey={1024}
          onLoad={() => null}
        />
        <Picture
          mobile={ucLogoMobileWhite}
          desktop={ucLogoWhite}
          alt='Ufuk Çetincan'
          className='header-text-ufuk white'
          pointkey={1024}
          onLoad={() => null}
        />
        <Text
          className={`mobile ${
            mainPageActiveIndex === 0 && !isBioPageOpen ? 'active' : ''
          }`}
        >
          Home
        </Text>
      </Link>
      <div>
        <H.Nav>
          <ul>
            <li
              className={
                mainPageActiveIndex === 0 && !isBioPageOpen ? 'active' : ''
              }
            >
              <Link to='/'>
                <Text>Home</Text>
              </Link>
            </li>
            <li
              className={
                mainPageActiveIndex !== 0 && !isBioPageOpen ? 'active' : ''
              }
            >
              <Link to='/#work'>
                <Text>Work</Text>
              </Link>
            </li>
            <li className={isBioPageOpen ? 'active' : ''}>
              <Link to='/Biography'>
                <Text>Bio</Text>
              </Link>
            </li>
          </ul>
        </H.Nav>
      </div>
    </H.Header>
  );
}
