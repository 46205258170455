import styled from '@emotion/styled';

export const N = {};

N.Container = styled.div`
  min-width: 620px;
  > div {
    height: 108px;
  }
  > div > div {
    height: 150px;
    max-height: 130px;
    overflow: hidden;
    transition: max-height 600ms cubic-bezier(0.77, 0, 0.175, 1);
  }
  &.gone > div > div {
    max-height: 0;
  }
  @media (max-width: 600px) {
    > div {
      height: 60px;
    }
    > div > div {
      height: 80px;
      max-height: 90px;
    }
    .Self-App {
      color: transparent;
      &:before {
        content: 'Self';
        color: #000;
      }
    }
  }
`;
