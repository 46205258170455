import styled from '@emotion/styled';
import { fontFamilies } from '../../constants/fonts';

export const T = {};

const sizes = {
  xxl: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 190,
    lineHeight: 1.05,
    letterSpacing: '-0.045em',
    '@media (max-width:1024px)': {
      fontSize: 150,
    },
  },
  xl: {
    fontSize: 120,
    lineHeight: 0.9,
    letterSpacing: '-0.045em',
    '@media (max-width:600px)': {
      fontSize: 60,
      lineHeight: 1,
    },
  },
  lm: {
    fontSize: 82,
    lineHeight: '60px',
    letterSpacing: '-0.05em',
    '@media (max-width:600px)': {
      fontSize: 60,
      lineHeight: 1,
      letterSpacing: '-0.045em',
    },
  },
  lmx: {
    fontSize: 70,
    lineHeight: '60px',
    letterSpacing: '-0.04em',
    '@media (max-width:800px)': {
      fontSize: 47,
      lineHeight: '54px',
    },
  },
  lmm: {
    fontSize: 36,
    lineHeight: '44px',
    '@media (max-width:800px)': {
      fontSize: 22,
      lineHeight: '24px',
    },
  },
  l: {
    fontSize: 32,
    lineHeight: 1,
    letterSpacing: '-0.01em',
    '@media (max-width:800px)': {
      fontSize: 21,
      lineHeight: 1,
      letterSpacing: '-0.045em',
    },
    '@media (max-width:600px)': {
      fontSize: 19,
    },
  },
  lx: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 28,
    lineHeight: '34.19px',
    letterSpacing: '-0.04em',
    '@media (max-width:600px)': {
      fontSize: 20,
      lineHeight: '30px',
      letterSpacing: ' -0.01em',
    },
  },
  mdx: {
    fontSize: 23,
    lineHeight: '32px',
    letterSpacing: '-0.01em',
    '@media (max-width:600px)': {
      fontSize: 20,
      lineHeight: '30px',
      letterSpacing: '-0.005em',
    },
  },

  md: {
    fontStyle: 'normal',
    fontSize: 20,
    lineHeight: 1.15,
    letterSpacing: '-0.02em',
    '@media (max-width:600px)': {
      fontSize: 18,
      letterSpacing: '-0.025em',
    },
  },
  sm: {
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: '20px',
    letterSpacing: ' -0.035em',
    '@media (max-width:800px)': {
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '-0.02em',
    },
  },
  sx: {
    fontSize: 16,
    lineHeight: '32px',
    letterSpacing: '0.01em',
  },
};

T.Text = styled.p(({ color, size, font, underline }) => {
  return {
    color: color,
    textDecoration: underline ? 'underline' : 'none',
    ...sizes[size],
    ...fontFamilies[font],
    '& textarea, &.scroll-animation-elem  > span': {
      color: color,
      textDecoration: underline ? 'underline' : 'none',
      ...sizes[size],
      ...fontFamilies[font],
    },
  };
});
