import styled from "@emotion/styled"
import Link from "gatsby-link"

export const L = {}

L.Link = styled(Link)({
  cursor: "pointer",
})

L.ExternalLink = styled.a({
  cursor: "pointer",
})
