import {
  setAllImagesLoadingDone,
  updateBioPageOpenStatus,
  updateDetailBannerRestShow,
  updateDetaiLoadingStatus,
  updateHeaderBgGone,
  updateHomeTextGone,
  updateMainPageIndex,
  updateSelectedSectionIndex,
  updateImageLoadedCount,
  updateMainPageStatus,
  resetImageLoadedCount,
  resetSlice,
} from '../features/animationsSlice';

import sections from '../constants/sections';

import { store } from '../app/store';
import { addClass, hasClass, removeClass } from '../utils/evensts';
import { openWorkDefault } from './urlTracker';

const dispatch = store.dispatch;
const timeouts = [];

export const _returnUrlSectionIndex = () => {
  const pathname = window.location.pathname.split('/')[1];
  const index = pathname.length < 2 ? 0 : sections[pathname]?.index;

  return index;
};

export const _updateMainPageIndex = (index, direction) =>
  dispatch(updateMainPageIndex({ index, direction }));

export const _handleMainPageScroll = (index, direction) => {
  _handleHomeText(false);
  _updateMainPageIndex(index, direction);
};

export const _updateBioPageOpen = (status) => {
  removeClass('.bottom-to-top', 'bottom-to-top');
  if (status) {
    const time = hasClass(document.getElementById('content-box'), 'work-page')
      ? 1500
      : 0;

    timeouts.push(
      setTimeout(() => {
        resetSlice();
        _updateMainPageIndex(-1);
      }, time)
    );

    addClass(document.body, 'bio-page');

    timeouts.push(
      setTimeout(() => {
        addClass('#bio-content', 'ready');
        scrollTopSelected();
        removeStyleFromActive();
      }, 500)
    );
  } else {
    removeClass('#bio-content', 'ready');
    removeClass(document.body, 'bio-page');

    addClass(document.body, 'animate-home');
    setTimeout(() => {
      removeClass(document.body, 'animate-home');
    }, 1000);
  }

  dispatch(updateBioPageOpenStatus({ status: status }));
};

export const _updateSectionIndex = (index) => {
  dispatch(updateSelectedSectionIndex({ index: index }));
};

export const _updateHeaderBgGon = (status) => {
  dispatch(updateHeaderBgGone({ status }));
};

export const _homeToDetail = () => {
  const index = _returnUrlSectionIndex();

  _updateSectionIndex(index);
  _updateHeaderBgGon(true);
  _handleHomeText(true);
  timeouts.push(
    setTimeout(() => {
      // dispatch(updateMainPageIndex({ index: index }));
      _handleDetailRest(true);
      _handleDetailLoading(true);
    }, 800)
  );
};

export const _popToSection = () => {
  const index = _returnUrlSectionIndex();

  _updateSectionIndex(index);
  _updateHeaderBgGon(true);
  _handleHomeText(true);
  timeouts.push(
    setTimeout(() => {
      dispatch(updateMainPageIndex({ index: index }));
    }, 800)
  );
};

export const _handleHomeText = (status) => {
  dispatch(updateHomeTextGone({ status }));
};

export const _handleDetailRest = (status) => {
  dispatch(updateDetailBannerRestShow({ status }));
};

export const _handleAllImageDoneCallback = (status) => {
  if (
    hasClass(document.getElementById('content-container'), 'active-section')
  ) {
    dispatch(setAllImagesLoadingDone({ status }));
    _handleDetailLoading(false);
  }
};

export const _handleDetailLoading = (status) => {
  dispatch(updateDetaiLoadingStatus({ status }));
};

export const _detailToWork = (callback) => {
  scrollAllSectionsToTop();

  addClass('#content-body', 'return-work');
  _handleAllImageDoneCallback(false);
  _handleDetailRest(false);
  removeClass('#content-container > .section.first-section', 'bottom-to-top');
  document
    .querySelectorAll(
      '#content-container > .section.first-section > div:nth-of-type(2)'
    )[0]
    .setAttribute('style', '');

  timeouts.push(setTimeout(() => _updateSectionIndex(-1), 500));
  timeouts.push(setTimeout(() => _updateHeaderBgGon(false), 1400));
  timeouts.push(
    setTimeout(() => {
      _handleHomeText(false);
      removeClass('#content-body', 'return-work');
      document
        .querySelectorAll('.section .section-banner')[0]
        .setAttribute('style', '');
      removeStyleFromActive();
      if (typeof callback === 'function') callback();
    }, 1450)
  );
};

export const _detailToHome = () => {
  _detailToWork(() => _updateMainPageIndex(0));
};

export const _detailtoPushWork = () => {
  _detailToWork(() => _updateMainPageIndex(1));
};

export const _bioToWork = () => {
  _updateBioPageOpen();
  timeouts.push(
    setTimeout(() => {
      scrollTopSelected();
      removeStyleFromActive();
      timeouts.push(
        setTimeout(() => {
          document.getElementById('bio-content').scrollTop = 0;
        }, 500)
      );
      dispatch(updateBioPageOpenStatus({ status: false }));
      dispatch(resetImageLoadedCount({ array: [] }));
      dispatch(setAllImagesLoadingDone({ status: false }));
      dispatch(updateDetailBannerRestShow({ status: false }));
      openWorkDefault();
      dispatch(updateSelectedSectionIndex({ index: -1 }));
    }, 300)
  );
};

export const _bioToHome = () => {
  timeouts.push(
    setTimeout(() => {
      document.getElementById('bio-content').scrollTop = 0;
    }, 500)
  );
  if (hasClass(document.querySelector('header'), 'detail')) {
    _updateBioPageOpen(false);
    _detailToHome();
  } else {
    _updateBioPageOpen(false);
    timeouts.push(
      setTimeout(() => {
        _updateMainPageIndex(0);
      }, 500)
    );
  }
};

export const _bioToHomePop = () => {
  addClass(document.body, 'fresh-home page-setted');
  _updateBioPageOpen(false);
  timeouts.push(
    setTimeout(() => {
      _updateMainPageIndex(0);
    }, 500)
  );
};

export const clearAllTimeouts = () => {
  for (var i = 0; i < timeouts.length; i++) {
    clearTimeout(timeouts[i]);
  }
};

export const scrollAllSectionsToTop = () => {
  var sections = document.querySelectorAll('#content-container > .section');
  for (let index = 0; index < sections.length; index++) {
    const element = sections[index];
    element.scrollTop = 0;
  }
};

export const _mainToBio = () => {
  removeClass('#content-container > .section.first-section', 'bottom-to-top');
  _updateBioPageOpen(true);
};

export const _detailToDetail = () => {
  removeClass('.section.selected', 'selected');
  scrollAllSectionsToTop();

  _handleDetailRest(false);
  _handleAllImageDoneCallback(false);

  const index = _returnUrlSectionIndex();

  _updateMainPageIndex(index);
  _updateSectionIndex(index);

  timeouts.push(
    setTimeout(() => {
      scrollAllSectionsToTop();
      _handleDetailRest(true);
      _handleDetailLoading(true);
      _handleHomeText(true);
      document
        .querySelectorAll('.section.active .section-banner')[0]
        .setAttribute('style', '');

      removeStyleFromActive();
    }, 500)
  );
};

export const _updateMainPageImageLoad = (name) => {
  dispatch(updateImageLoadedCount({ name }));
};

export const _updateMainPageLoaded = (status) => {
  dispatch(updateMainPageStatus({ status }));
};

export function _poptoDetailFromBio() {
  removeClass('#bio-content', 'ready');
  removeClass(document.body, 'bio-page');

  dispatch(updateBioPageOpenStatus({ status: false }));
  dispatch(
    updateMainPageIndex({
      index: _returnUrlSectionIndex(),
      direction: 'down',
    })
  );
}

function scrollTopSelected() {
  if (typeof document.querySelectorAll('.section.selected')[0] !== 'undefined')
    document.querySelectorAll('.section.selected')[0].scrollTop = 0;
}

function removeStyleFromActive() {
  if (typeof document.querySelectorAll('.section.active')[0] !== 'undefined')
    document.querySelectorAll('.section.active')[0].setAttribute('style', '');
}
