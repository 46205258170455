import { ThemeProvider } from '@emotion/react';
import React from 'react';
import { Provider } from 'react-redux';

import Header from '../Header/Header';

import '../../../static/fonts/stylesheet.css';
import '../../../static/style.css';

import theme from '../../theme';

import { store } from '../../app/store';

export function Layout({ children, ...rest }) {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Header />
        <div>{children}</div>
      </ThemeProvider>
    </Provider>
  );
}
