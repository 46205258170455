import theme from '../theme';

import cpNextImage from '../assets/images/cp/loreal-next-image.jpg';
import cpBanner from '../assets/images/cp/Loreal_ContentPro_banner.jpg';
import cpMobileBanner from '../assets/images/cp/Loreal_ContentPro_banner_mobile.jpg';
import bncffNextImage from '../assets/images/bncff/bncff-next-image.jpg';
import bncffBannerImage from '../assets/images/bncff/BNCFF_Showcase_banner.jpg';
import bncffBannerMobileImage from '../assets/images/bncff/BNCFF_Showcase_banner_mobile.jpg';
import spitzNextImage from '../assets/images/spitz/spitz-nextImage.jpg';
import spitzBannerImage from '../assets/images/spitz/Spitz_Germany_banner.jpg';
import spitzBannerMobileImage from '../assets/images/spitz/Spitz_Germany_banner_mobile.jpg';
import wamoNextImage from '../assets/images/wamo/wamo-nextImage.jpg';
import wamoBanner from '../assets/images/wamo/Wamo_banner.jpg';
import wamoMobileBanner from '../assets/images/wamo/Wamo_mobile.jpg';
import selfAppNextImage from '../assets/images/sa/self-app-next.jpg';
import selfAppBannerImage from '../assets/images/sa/SelfApp_banner.jpg';
import selfAppMobileBannerImage from '../assets/images/sa/SelfApp_banner_mobile.jpg';
import fpNextImage from '../assets/images/fp/fp-nextImage.jpg';
import fpBanner from '../assets/images/fp/Fifth_Position_banner.jpg';
import fpMobileBanner from '../assets/images/fp/Fifth_Position_banner_mobile.jpg';
import bunkerNextImage from '../assets/images/bunker/bunker-nextImage.jpg';
import bunkerImage from '../assets/images/bunker/Bunker_Customs_banner.jpg';
import bunkerMobileImage from '../assets/images/bunker/Bunker_Customs_banner_mobile.jpg';
import lsaNextImage from '../assets/images/lsa/lsa-nextImage.jpg';
import lsaBannerImage from '../assets/images/lsa/Lsa_banner.jpg';
import lsaBannerMobileImage from '../assets/images/lsa/Lsa_banner_mobile.jpg';
import abNextImage from '../assets/images/ab/alawwal-nextImage.jpg';
import abBannerImage from '../assets/images/ab/alawwal-bank-banner.jpg';
import abBannerMobileImage from '../assets/images/ab/alawwal-bank-banner-mobile.jpg';

const sections = {
  Home: {
    index: 0,
    name: 'Home',
    title: '',
  },
  LorealContentPro: {
    index: 1,
    name: 'LorealContentPro',
    title: 'L’Oréal ContentPro',
    subtitle: 'PRODUCT DESIGN & GAMIFICATION',
    imageUrl: cpBanner,
    mobileImage: cpMobileBanner,
    description:
      'L’Oréal ContentPro is the world’s first content creator & influencer loyalty platform, and all-in-one app. It features curated content projects, product reviews, social commerce, and exclusive benefits. The extensive gamification system rewards active members, while elevating content quality and increasing user engagement. Social commerce enables members to generate income, while product review system creates a bridge between make-up artists and newbies. ',
    nextImage: cpNextImage,
    nextItem: 'BNCFFShowcase',
    arrowColor: '#fff',
    mobileArrowColor: '#fff',
  },
  BNCFFShowcase: {
    index: 2,
    name: 'BNCFFShowcase',
    title: 'BNCFF Showcase',
    subtitle: 'WEBSITE DESIGN',
    imageUrl: bncffBannerImage,
    mobileImage: bncffBannerMobileImage,
    nextImage: bncffNextImage,
    nextItem: 'SpitzGermany',
    description: `
    Backed by the Government of the Grand Duchy of Luxembourg and Total Foundation, the BNCFF (Blue Nature Capital Financing Facility) fosters the development of innovative, nature-based, economically sustainable projects with clear ecosystem service benefits and multiple income streams.
    \nThe website is a showcase for BNCFF projects around the world. It features four-way (vertical + horizontal) scrolling and multi layered navigation structure for enhanced visual experience. With this layout, the website acts like a single pager showcase, despite having more than a dozen sub pages.`,
  },
  SpitzGermany: {
    index: 3,
    name: 'SpitzGermany',
    title: 'Spitz Germany',
    subtitle: 'ART DIRECTION, WEBSITE DESIGN',
    imageUrl: spitzBannerImage,
    mobileImage: spitzBannerMobileImage,
    nextImage: spitzNextImage,
    nextItem: 'Wamo',
    description:
      'Spitz is a decentralized network of designers and provides branding and digital products. The word comes from German meaning sharp, edgy, witty and horny. I wanted to create a visual language from an interplay of most basic forms such as circles, squares and triangles to create a minimalistic blend of human-centric approach and wittiness.',
    mainColor: theme.colors.mercury,
  },
  Wamo: {
    index: 4,
    name: 'Wamo',
    title: 'Wamo',
    subtitle: 'ART DIRECTION, WEBSITE DESIGN',
    imageUrl: wamoBanner,
    mobileImage: wamoMobileBanner,
    nextImage: wamoNextImage,
    nextItem: 'SelfApp',
    description:
      'Wamo is a next gen financial management platform. It puts all banking features in one place, adding contactless payments and various business components to make sending and receiving payments a breeze. I have designed their visual language, supervised the creation of the graphic assets, and designed their website. A high-contrast and reduced color palette was combined with modern illustrative style to create a minimalistic, yet dynamic appearance.',
    arrowColor: '#fff',
    mobileArrowColor: '#fff',
  },
  SelfApp: {
    index: 5,
    name: 'SelfApp',
    title: 'Self\u00A0App',
    subtitle: 'PRODUCT DESIGN',
    imageUrl: selfAppBannerImage,
    mobileImage: selfAppMobileBannerImage,
    nextImage: selfAppNextImage,
    nextItem: 'FifthPosition',
    color: theme.colors.water,
    description: `SELF is an advanced metitation app. It's both a digital tool to help meditators, and a data analysis tool to gather meaningful insights about their practice. It utilizes mobile tech like gyroscope and voice recognition to translate abstract subjects like "feelings" and "conciousness" into quantifiable data. This unique approach is then converted into visual design as low-poly abstractions of the nature and the mind, rather than the conventional representations like aum signs and lotus flowers.`,
  },
  FifthPosition: {
    index: 6,
    name: 'FifthPosition',
    title: 'Fifth Position',
    subtitle: 'ART DIRECTION, WEBSITE DESIGN',
    imageUrl: fpBanner,
    mobileImage: fpMobileBanner,
    nextImage: fpNextImage,
    nextItem: 'BunkerCustoms',
    description:
      'Fifth Position is an upscale beachwear brand based in Amsterdam and Istanbul. Their designs are inspired by the strong and graceful silhouettes of ballet dancers. The aim was to reflect the concept of “movement and freeflow” in their website design. I incorporated the waveform and blobs from the branding to use as graphic elements. Asymmetric and free flowing elements were the key features of the design system.',
    mainColor: theme.colors.lightRed,
    secondaryColor: theme.colors.alabaster,
    arrowColor: '#fff',
    mobileArrowColor: '#fff',
  },
  BunkerCustoms: {
    index: 7,
    imageUrl: bunkerImage,
    mobileImage: bunkerMobileImage,
    name: 'BunkerCustoms',
    title: 'Bunker Customs',
    subtitle: 'WEBSITE DESIGN',
    nextImage: bunkerNextImage,
    nextItem: 'Lsa',
    description: `Bunker is a custom motorcycle company, founded in 2009 by Mert and Can Uzer Brothers. It is by far the most successful and globally acclaimed bike design studio in Turkey.
    \n
    Uzer Brothers were in love with bikes, but they weren't satisfied with conventional methods of craftsmanship. They wanted their bikes to be as efficient, functional and good looking as possible. I wanted to reflect this passion, as well as the obvious finesse in their craftmanship. There’s a mixture of energy, modernism, and retro influences in Bunkers design, so my concept was to reflect these qualities from typography to visual design and user experience.`,
    arrowColor: '#fff',
  },
  Lsa: {
    index: 8,
    name: 'Lsa',
    title: 'LSA',
    subtitle: 'ART DIRECTION, WEBSITE DESIGN',
    imageUrl: lsaBannerImage,
    mobileImage: lsaBannerMobileImage,
    nextImage: lsaNextImage,
    nextItem: 'AlawwalBank',
    description:
      'Co-founded in 2011, and now solely owned by Hande Aykut, LSA houses a vast knowledge and expertise from mergers and acquisions to commercial disputes. The idea was to reflect the modern and urban look of their branding throughout the photography and the website. The design, enhanced with scroll animations, have an asymmetrical layout with ample white space. Typography also follows the form with neo-classical headlines combined with a modern body font.',
    arrowColor: '#fff',
  },
  AlawwalBank: {
    index: 9,
    name: 'AlawwalBank',
    title: 'Alawwal Bank',
    subtitle: 'DIGITAL ECOSYSTEM, WEBSITE & APP DESIGN',
    imageUrl: abBannerImage,
    mobileImage: abBannerMobileImage,
    nextImage: abNextImage,
    nextItem: 'LorealContentPro',
    description:
      'Saudi Hollandi Bank is Saudi’s first ever bank with a strong heritage and reputation. As they adapt to the new age by changing their name to Alawwal Bank, they needed a complete redesign of their digital presence. Me and my team were responsible for designing a modern, innovative and cohesive digital ecosystem that covered their website, online banking, mobile app and ATM interfaces.',
  },
  Biography: {
    index: 10,
    name: 'Biography',
    title: 'Biography',
    description: 'Description',
  },
};

export default sections;
