import styled from '@emotion/styled';
import React from 'react';

import Names from '../../components/Names/Names';
import sections from '../../constants/sections';

export default function Numbers({ delayClass }) {
  return (
    <NumberContainer id='numbers' className={delayClass}>
      {Object.keys(sections).map((key) => (
        <Names
          key={sections[key].index}
          title={sections[key].index === 0 ? '' : '0' + sections[key].index}
          sectionIndex={sections[key].index}
          fontSize='l'
          as='p'
        />
      ))}
    </NumberContainer>
  );
}

const NumberContainer = styled.div({
  position: 'absolute',
  width: 36,
  height: 31,
  pointerEvents: 'none',
  overflow: 'hidden',
  top: 0,
  left: 60,
  bottom: 0,
  margin: 'auto',
  zIndex: 900,
  transform: 'translate3d(0,-65px,0)',
  transition: 'transform 600ms ease-out, opacity 600ms ease-out',
  '&.down': {
    transitionDelay: '550ms!important',
    transition: 'transform 400ms ease-out,opacity 400ms ease-out',
  },

  '.home-page &': {
    opacity: 0,
    transform: 'translate3d(0,100%,0)',
  },
  '& > div': {
    height: 31,
    transition: 'opacity 400ms ease-out',
  },
  '&:not(.down) > div': {
    transition: 'opacity 400ms ease-out',
  },
  '& > div.active': {
    transitionDelay: '200ms',
  },
  '& p': {
    fontWeight: 700,
  },
  '@media (max-width:800px)': {
    left: 30,
    transform: 'translate3d(0,-30px,0)',
    '.home-page &': {
      transform: 'translate3d(-65px,-30px,0)!important',
    },
  },
});
