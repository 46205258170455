import React, { useEffect, useRef } from 'react';
import theme from '../../theme';
import { T } from './Text.styles';

export default function Text({
  color = theme.colors.black,
  size = 'md',
  bold,
  font = 'roman',
  children,
  underline,
  calcWidth,
  ...others
}) {
  const textRef = useRef();
  useEffect(() => {
    if (typeof calcWidth === 'function') calcWidth(textRef.current.clientWidth);
  }, [calcWidth]);

  return (
    <T.Text
      color={color}
      size={size}
      {...others}
      font={font}
      underline={underline}
      ref={textRef}
    >
      {children}
    </T.Text>
  );
}
