import React, { useState } from 'react';

import Section from '../../components/Section/Section';
import Description from '../../components/Description';
import sections from '../../constants/sections';
import Content from '../../components/Content';
import Picture from '../../components/Picture';
import Text from '../../components/Text/Text';
import Video from '../../components/Video';

import Footer from '../../components/Footer/Footer';

import { C } from '../Containers.styles';

import skectImage from '../../assets/images/ab/ab-sketches.jpg';
import skectMobileImage from '../../assets/images/ab/ab-sketches-mobile.jpg';
import devicesImage from '../../assets/images/ab/ab-devices.jpg';
import devicesMobileImage from '../../assets/images/ab/ab-devices-mobile.jpg';
import laptop1Image from '../../assets/images/ab/ab-laptop-1.jpg';
import laptop1MobileImage from '../../assets/images/ab/ab-laptop-1-mobile.jpg';
import laptop2Image from '../../assets/images/ab/ab-laptop-2.jpg';
import laptop2MobileImage from '../../assets/images/ab/ab-laptop-2-mobile.jpg';
import screen2Image from '../../assets/images/ab/ab-screen-2.jpg';
import screen2MobileImage from '../../assets/images/ab/ab-screen-2-mobile.jpg';
import onlineImage from '../../assets/images/ab/ab-online.jpg';
import onlineMobileImage from '../../assets/images/ab/ab-online-mobile.jpg';
import bankingImage from '../../assets/images/ab/ab-banking.jpg';
import bankingMobileImage from '../../assets/images/ab/ab-banking-mobile.jpg';
import twoImage from '../../assets/images/ab/ab-two-phones.jpg';
import twoMobileImage from '../../assets/images/ab/ab-two-phones-mobile.jpg';
import footerImage from '../../assets/images/ab/ab-footer.jpg';
import footerM0bileImage from '../../assets/images/ab/ab-footer-mobile.jpg';

import desktopVideo from '../../assets/videos/alawwal_desktop.mp4';
import mobileVideo from '../../assets/videos/alawwal_mobile.mp4';

import { ContentText } from '../../components/ContentText/ContentText';
import { useWindowDimensions } from '../../utils/window';

const AlawwalBank = () => {
  const name = 'AlawwalBank';
  const { description } = sections[name];
  const [loadedImageCount, setLoadedImageCount] = useState(0);
  const { width } = useWindowDimensions();
  const itemCount = width < 768 ? 9 : 10;

  return (
    <>
      <Section
        sectionName={name}
        itemCount={itemCount}
        loadedImageCount={loadedImageCount}
      >
        <Description desc={description} />
        <Content isShown={true} mobileFull={true}>
          <C.FullImage>
            <Picture
              mobile={skectMobileImage}
              desktop={skectImage}
              alt='Alawwal Bank sketches'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              className='scroll-animation-elem'
              data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.FullImage>
        </Content>
        <Content isShown={true}>
          <C.Description>
            <Text
              as='h4'
              size='sx'
              font='bold'
              className='scroll-animation-elem'
              data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            >
              THE WEBSITE
            </Text>
            <ContentText notHalf>
              Our keyword for the website design was “relevance”. As a typical
              banking website catered to different personal and corporate
              entities, the information was wast and overwhelming to everyday
              users. We reduced clutter by dynamically categorising,
              prioritising and color-coding content for specific user types,
              that resulted in faster navigation and lower bounce rates.
            </ContentText>
          </C.Description>
        </Content>
        <Content isShown={true} mobileFull={true}>
          <C.FullImage>
            <Picture
              mobile={devicesMobileImage}
              desktop={devicesImage}
              alt='Alawwal Bank devices'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              className='scroll-animation-elem'
              data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.FullImage>
        </Content>
        <Content isShown={true} mobileFull={true}>
          <C.HalfParent>
            <C.Half>
              <Picture
                mobile={laptop1MobileImage}
                desktop={laptop1Image}
                alt='Alawwal Bank screen'
                onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
                className='scroll-animation-elem'
                data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
              />
            </C.Half>
            <C.Half>
              <Picture
                mobile={laptop2MobileImage}
                desktop={laptop2Image}
                alt='Alawwal Bank screen'
                onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
                className='scroll-animation-elem'
                data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800,"delay":100}'
              />
            </C.Half>
          </C.HalfParent>
        </Content>
        <Content isShown={true} mobileFull={true}>
          <C.FullImage>
            <Picture
              mobile={screen2MobileImage}
              desktop={screen2Image}
              alt='Alawwal Bank screen'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              className='scroll-animation-elem'
              data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.FullImage>
        </Content>
        <Content isShown={true} mobileFull={true}>
          <C.HalfParent>
            <ContentText title='ONLINE BANKING'>
              Parallel to the core concept of "relevancy", online banking was
              rewamped for a more personal and approachable interface.
              Customizable favorites menu, self-learning suggestion system and
              simplified dashboard enabled faster and more precise actions.
            </ContentText>

            <C.Half>
              <Picture
                mobile={onlineMobileImage}
                desktop={onlineImage}
                alt='Alawwal Bank screen'
                onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
                className='scroll-animation-elem'
                data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
              />
            </C.Half>
          </C.HalfParent>
        </Content>
        <Content isShown={true} mobileFull={true}>
          <C.HalfParent>
            <C.Half>
              <Picture
                mobile={bankingMobileImage}
                desktop={bankingImage}
                alt='Alawwal Bank screen'
                onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
                className='scroll-animation-elem'
                data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
              />
            </C.Half>

            <ContentText title='ONLINE BANKING' className='left-side-padding'>
              Parallel to the core concept of "relevancy", online banking was
              rewamped for a more personal and approachable interface.
              Customizable favorites menu, self-learning suggestion system and
              simplified dashboard enabled faster and more precise actions.
            </ContentText>
          </C.HalfParent>
        </Content>
        <Content isShown={true} mobileFull={true}>
          <C.FullImage>
            <Picture
              mobile={twoMobileImage}
              desktop={twoImage}
              alt='Alawwal Bank screen'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              className='scroll-animation-elem'
              data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.FullImage>
        </Content>
        <Content isShown={true} mobileFull={true}>
          <C.HalfParent>
            <ContentText title='ENHANCED MICRO INTERACTION'>
              Micro interactions and animations not only add visual appeal, but
              also enhance the user experience significantly. This example shows
              nested menu actions that enables users to complete an action
              without a need to open a new page or an overlay.
            </ContentText>

            <C.Half>
              <Video
                isFull
                video={desktopVideo}
                mobileVideo={mobileVideo}
                onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
                className='scroll-animation-elem'
                backgroundColor='#e4e3e5'
                data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
              />
            </C.Half>
          </C.HalfParent>
        </Content>
        <Content isShown={true} mobileFull={true}>
          <C.FullImage>
            <Picture
              mobile={footerM0bileImage}
              desktop={footerImage}
              alt='Alawwal Bank app'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              className='scroll-animation-elem'
              data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.FullImage>
        </Content>
        <Footer />
      </Section>
    </>
  );
};
export default AlawwalBank;
