import React, { useRef, useState } from 'react';

import Section from '../../components/Section/Section';
import Description from '../../components/Description';

import sections from '../../constants/sections';
import Content from '../../components/Content';

import Video from '../../components/Video';
import Footer from '../../components/Footer/Footer';
import FullImage from '../../components/FullImage';
import { useWindowDimensions } from '../../utils/window';

import webSite1Image from '../../assets/images/bncff/bncff-web-site-1.jpg';
import webSite1MobileImage from '../../assets/images/bncff/bncff-web-site-1-mobile.jpg';
import webSite2Image from '../../assets/images/bncff/bncff-web-site-2.jpg';
import webSite2MobileImage from '../../assets/images/bncff/bncff-web-site-2-mobile.jpg';
import app1Image from '../../assets/images/bncff/bncff-app-1.jpg';
import app1MobileImage from '../../assets/images/bncff/bncff-app-1-mobile.jpg';
import webSite3Image from '../../assets/images/bncff/bncff-web-site-3.jpg';
import webSite3MobileImage from '../../assets/images/bncff/bncff-web-site-3-mobile.jpg';
import webSite4Image from '../../assets/images/bncff/bncff-web-site-4.jpg';
import webSite4MobileImage from '../../assets/images/bncff/bncff-web-site-4-mobile.jpg';
import screensImage from '../../assets/images/bncff/bncff-screens.jpg';
import screensMobileImage from '../../assets/images/bncff/bncff-screens-mobile.jpg';
import app2Image from '../../assets/images/bncff/bncff-mobile-2.jpg';
import app2MobileImage from '../../assets/images/bncff/bncff-mobile-2-mobile.jpg';
import app3Image from '../../assets/images/bncff/bncff-mobile-3.jpg';
import app3MobileImage from '../../assets/images/bncff/bncff-mobile-3-mobile.jpg';
import webSite5Image from '../../assets/images/bncff/bncff-web-site-5.jpg';
import webSite5MobileImage from '../../assets/images/bncff/bncff-web-site-5-mobile.jpg';
import webSite6Image from '../../assets/images/bncff/bncff-web-site-6.jpg';
import webSite6MobileImage from '../../assets/images/bncff/bncff-web-site-6-mobile.jpg';

import bncffVideo from '../../assets/videos/bncff.mp4';

const BNCFFShowcase = () => {
  const name = 'BNCFFShowcase';
  const { description, mainColor } = sections[name];
  const [loadedImageCount, setLoadedImageCount] = useState(0);
  const { width } = useWindowDimensions();
  const itemCount = width < 768 ? 10 : 11;

  return (
    <Section
      sectionName={name}
      itemCount={itemCount}
      loadedImageCount={loadedImageCount}
    >
      <Description desc={description} />
      <Content
        backgroundColor='#ededed'
        isShown={true}
        isFull={true}
        data-top='1'
      >
        <Video
          backgroundColor='#ededed'
          isFull
          video={bncffVideo}
          mobileVideo={bncffVideo}
          onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
          className='scroll-animation-elem'
          data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        />
      </Content>

      <FullImage
        mainColor={mainColor}
        image={webSite1Image}
        mobileImage={webSite1MobileImage}
        alt='BNFCC Web Site Screens'
        loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
      />
      <FullImage
        mainColor={mainColor}
        image={webSite2Image}
        mobileImage={webSite2MobileImage}
        alt='BNFCC Web Site Screens'
        loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
      />
      <FullImage
        mainColor={mainColor}
        image={app1Image}
        mobileImage={app1MobileImage}
        alt='BNFCC App Screens'
        loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
      />
      <FullImage
        mainColor={mainColor}
        image={webSite3Image}
        mobileImage={webSite3MobileImage}
        alt='BNFCC App Screens'
        loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
      />
      <FullImage
        mainColor={mainColor}
        image={webSite4Image}
        mobileImage={webSite4MobileImage}
        alt='BNFCC App Screens'
        loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
      />
      <FullImage
        mainColor={mainColor}
        image={screensImage}
        mobileImage={screensMobileImage}
        alt='BNFCC App Screens'
        loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
      />
      <FullImage
        mainColor={mainColor}
        image={app2Image}
        mobileImage={app2MobileImage}
        alt='BNFCC App Screens'
        loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
        // className='scroll-animation-elem'
        // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
      />
      <FullImage
        mainColor={mainColor}
        image={app3Image}
        mobileImage={app3MobileImage}
        alt='BNFCC App Screens'
        loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
        // className='scroll-animation-elem'
        // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
      />
      <FullImage
        mainColor={mainColor}
        image={webSite5Image}
        mobileImage={webSite5MobileImage}
        alt='BNFCC App Screens'
        loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
        // className='scroll-animation-elem'
        // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
      />
      <FullImage
        mainColor={mainColor}
        image={webSite6Image}
        mobileImage={webSite6MobileImage}
        alt='BNFCC App Screens'
        loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
        // className='scroll-animation-elem'
        // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
      />
      <Footer />
    </Section>
  );
};
export default BNCFFShowcase;
