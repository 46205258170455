import styled from '@emotion/styled';
import theme from '../../theme';

export const F = {};

const contentstyle = {
  width: '100%',
  maxWidth: 1157,
  padding: '0 30px',
  margin: 'auto',
  display: 'flex',
  '& img, & picture': {
    width: '100%',
    display: 'block',
  },
};

F.Devices = styled.div({
  ...contentstyle,
  margin: '100px auto',

  '@media (max-width:800px)': {
    margin: '40px auto',
  },
});

F.WebSite = styled.div({
  ...contentstyle,
});

F.WebSiteTop = styled.div({
  ...contentstyle,
  marginTop: 160,
  '@media (max-width:800px)': {
    marginTop: 40,
  },
});

F.WebSiteBottom = styled.div({
  ...contentstyle,
  marginBottom: 160,
  '@media (max-width:800px)': {
    marginBottom: 40,
  },
});

F.Screens = styled.div({
  width: '100%',
  display: 'flex',
  '& img, & picture': {
    width: '100%',
    display: 'block',
  },
});

F.ScreensBottom = styled.div({
  width: '100%',
  marginBottom: 136,
  '& img, & picture': {
    width: '100%',
    display: 'block',
  },
  '@media (max-width:800px)': {
    marginBottom: 35,
  },
});

F.ScreensCaruosel = styled.div({
  width: '100%',
  paddingBottom: 42,
  backgroundColor: theme.colors.lightRed,
  '& img, & picture': {
    width: '100%',
    display: 'block',
  },
  '@media (max-width:800px)': {
    paddingBottom: 23,
  },
});

F.FirstVideo = styled.div({
  background: '#eeedee',
  padding: '119px 0',
  '@media (max-width:800px)': {
    padding: ' 0 0',
    '& > div > div > div ': {
      padding: '24px 0',
    },
  },
});
