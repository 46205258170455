import React, { useEffect, useRef } from 'react';

const Image = ({ src, onLoad, alt, ...props }) => {
  const image = useRef();
  const handleLoad = (e) => setImageLoad(e);
  const setImageLoad = (e) => {
    onLoad(e);
  };

  useEffect(() => {
    if (image.current && image.current.complete) {
      setImageLoad();
    }
  }, []);

  return (
    <img
      src={src}
      onLoad={(e) => handleLoad(e)}
      alt={alt}
      {...props}
      ref={image}
    />
  );
};

export default Image;
