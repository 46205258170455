import React from 'react';

import { Layout } from './src/components/Layout/Layout';
import Container from './src/containers/Container';

export const wrapRootElement = ({ element, props }) => {
  return (
    <Layout {...props}>
      <Container />
      {element}
    </Layout>
  );
};
