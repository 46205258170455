import styled from '@emotion/styled';
import Text from '../Text/Text';

export const T = {};

T.Text = styled(Text)`
  span {
    display: inline-block;
  }
`;
