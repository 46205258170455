import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import ContentHeader from '../ContentHeader';
import Link from '../Link/Link';

import sections from '../../constants/sections';

import { _updateMainPageImageLoad } from '../../helpers/helper';
import BannerContent from '../BannerContent/BannerContent';
import * as B from './Banner.styles';

import {
  selectAllBannerImageLoadedCount,
  selectIsBannerRestShown,
  selectIsDetailHeaderAndContentShown,
  selectMainPageActiveIndex,
  selectSelectedSectionIndex,
} from '../../features/selectors';

const Banner = ({ sectionName, children }) => {
  const { mobileImage, imageUrl, title, subtitle } = sections[sectionName];
  const [_, setLoaded] = useState(false);
  const [maxHeight, setMaxHeight] = useState(351);
  const index = sections[sectionName].index;
  const image = useRef();

  const isBannerRestShown = useSelector(selectIsBannerRestShown);
  const selectedSectionIndex = useSelector(selectSelectedSectionIndex);
  const allBannerImageLoadedCount = useSelector(
    selectAllBannerImageLoadedCount
  );
  const isDetailHeaderAndContentShown = useSelector(
    selectIsDetailHeaderAndContentShown
  );
  const mainPageActiveIndex = useSelector(selectMainPageActiveIndex);
  const containerClass = `${isDetailHeaderAndContentShown ? 'shrink' : ''} ${
    mainPageActiveIndex === 0 ? 'home-selected' : ''
  }`;

  const handleLoad = () => setImageLoad();

  const setImageLoad = () => {
    if (typeof window !== 'undefined') {
      if (!allBannerImageLoadedCount.includes(sectionName)) {
        _updateMainPageImageLoad(sectionName);
      }

      setLoaded(true);
    }
  };

  useEffect(() => {
    if (image.current && image.current.complete) {
      setImageLoad();
    }
  }, []);

  return (
    <>
      <BannerContent
        isShown={
          isDetailHeaderAndContentShown && selectedSectionIndex !== -1
            ? 'shown'
            : ''
        }
        maxHeight={maxHeight}
        isBanner={true}
        backgroundColor='#fff'
      >
        <ContentHeader
          subtitle={subtitle}
          title={title}
          isShown={isDetailHeaderAndContentShown && selectedSectionIndex !== -1}
          setMaxHeight={(e) => setMaxHeight(e)}
          onTransitionEnd={() => setMaxHeight(500)}
        />
      </BannerContent>
      <B.Container className={containerClass}>
        <B.Banner className='section-banner'>
          <Link to={`/${sectionName}`}>
            <picture>
              <source media='(max-width: 600px)' srcSet={mobileImage} />
              <source media='(min-width: 601px)' srcSet={imageUrl} />
              <img src={imageUrl} alt={title} ref={image} onLoad={handleLoad} />
            </picture>
          </Link>
        </B.Banner>
      </B.Container>
      {isBannerRestShown && selectedSectionIndex === index && <>{children}</>}
    </>
  );
};

export default Banner;
