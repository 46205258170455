import styled from '@emotion/styled';

export const N = {};

N.Content = styled.div`
  height: 410px;
  position: fixed;
  overflow: hidden;
  width: 100%;
  bottom: 0;
  z-index: 0;
  opacity: 0;
  .scroll-half & {
    opacity: 1;
  }

  @media screen and (max-width: 800px) {
    height: 210px;
  }
`;

N.Container = styled.div`
  background-color: transparent;
  position: relative;
  height: 410px;

  cursor: pointer;

  img {
    width: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }
  a {
    display: block;
    pointer-events: all !important;

    :after {
      content: '';
      display: block;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: #000;
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
      transition: transform 250ms ease-out, opacity 250ms ease-out;
      z-index: 20;
    }

    .next-hover &:after {
      opacity: 0.5;
      transform: translate3d(0, 0, 0);
    }

    .next-hover & > div h4 img {
      left: 110%;
      opacity: 1;
    }
  }
  a > div {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 60px;
    z-index: 30;

    h4 {
      margin-bottom: 20px;
      position: relative;
      img {
        width: 0.8em;
        height: auto;
        display: block;
        position: absolute;
        left: 90%;
        top: 0.35em;
        opacity: 0;
        transition: left 250ms ease-out, opacity 250ms ease-out;
      }
    }
    h4,
    p {
      transition: transform 250ms ease-out;
    }

    @media (max-width: 800px) {
      padding-left: 30px;
      h4 {
        margin-bottom: 10px;
      }
    }
  }

  .next-hover & a > div h4,
  .next-hover & a > div p {
    transform: translate3d(30px, 0, 0);
    color: #fff;

    span {
      color: #fff;
    }
  }
  @media (max-width: 800px) {
    height: 210px;
    h4 span {
      display: none;
    }
  }
`;

N.Shade = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000;
  opacity: 1;
`;
