import styled from '@emotion/styled';

export const L = {};

L.Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: #fff;
`;
