import behancelogo from "../assets/images/behance-logo.svg"
import instagramlogo from "../assets/images/instagram-logo.svg"
import linkedinlogo from "../assets/images/linkedin-logo.svg"
import maillogo from "../assets/images/mail-logo.svg"

const socials = {
  behance: {
    to: "https://www.behance.net/",
    icon: behancelogo,
  },
  instagram: {
    to: "https://www.instagram.com/",
    icon: instagramlogo,
  },
  linkedin: {
    to: "https://www.linkedin.com/",
    icon: linkedinlogo,
  },
  mail: {
    to: "/",
    icon: maillogo,
  },
}

export default socials
