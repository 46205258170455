import styled from '@emotion/styled';
import { fontFamilies } from '../../constants/fonts';
import theme from '../../theme';
import { keyframes } from '@emotion/react';

export const H = {};

const logoCome = keyframes`
 0%{transform:translate3d(-50%,0,0);opacity:0}
 100%{transform:translate3d(0,0%,0);opacity:1}
`;

const navCome = keyframes`
 0%{transform:translate3d(0,-200%,0)}
 100%{transform:translate3d(0,0%,0);opacity:1}
`;

H.Header = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 888;
  padding-top: ${theme.spacing.lg}px;
  padding-left: ${theme.spacing.md}px;
  padding-right: ${theme.spacing.md}px;
  padding-bottom: 60px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: ${theme.colors.cream};
  transition: background 0ms ease;
  &.bio {
    transition-delay: 600ms;
    background: transparent;
  }
  .header-text-ufuk {
    width: 140.88px;
    opacity: 0;
  }
  .header-text-ufuk.white,
  &.light .header-text-ufuk:not(.white) {
    display: none;
  }

  &.light .header-text-ufuk.white {
    display: block;
  }
  .header-line {
    display: inline-block;
    vertical-align: middle;
    height: 1px;
    width: 1em;
    background: black;
  }
  &.detail {
    background: transparent;
  }
  &.home > a {
    pointer-events: none;
  }
  > a {
    height: 19px;
    display: block;
    position: relative;
  }
  a p {
    letter-spacing: -0.045em;
  }
  a .mobile {
    display: none;
  }
  > * {
    transition: transform 400ms ease-in-out;
  }
  &.detail.header-up > *,
  &.bio.header-up > * {
    transform: translate3d(0, -150px, 0);
  }
  & li p {
    line-height: 0.9;
  }
  & > a * {
    transform: translate3d(-60%, 0, 0);
    transition: transform 500ms cubic-bezier(0.14, 0.35, 0.51, 0.95),
      opacity 500ms cubic-bezier(0.14, 0.35, 0.51, 0.95);
  }
  body.loaded & > a * {
    transform: translate3d(0, 0, 0);
  }
  body.loaded & > a .header-text-ufuk {
    transform: translate3d(0, 300%, 0);
  }
  a > .header-text-ufuk {
    transform: translate3d(0, 300%, 0);
    display: block;
  }
  body.loaded &.work a .header-text-ufuk,
  body.loaded &.detail a .header-text-ufuk,
  body.loaded &.bio a .header-text-ufuk {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition-delay: 50ms;
  }
  @media (max-height: 700px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media (min-width: 1024px) {
    a > h1 {
      width: 160px;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
    body.loaded &.pre a > h1 {
      animation: ${logoCome} 600ms cubic-bezier(0.14, 0.35, 0.51, 0.95);
    }
    body.loaded &.work a > h1,
    body.loaded &.detail a > h1,
    body.loaded &.bio a > h1 {
      transform: translate3d(0, -80%, 0);
      opacity: 0;
    }
    body.loaded &.home:not(.bio) a > h1 {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @media (max-width: 1024px) {
    body.loaded & {
      animation: ${navCome} 500ms cubic-bezier(0.14, 0.35, 0.51, 0.95) forwards;
    }
    a > h1 {
      display: none;
    }
    a .mobile {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      line-height: 0.9;
      transform: translate3d(0, 0%, 0);
    }

    body.loaded &.work a .mobile,
    body.loaded &.detail a .mobile,
    body.loaded &.bio a .mobile {
      transform: translate3d(0, -100%, 0);
      opacity: 0;
      transition-delay: 0;
    }
    body.loaded &.home:not(.bio) a .mobile {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @media (max-width: 800px) {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;

    .header-text-ufuk {
      ${{ ...fontFamilies.medium }}
      width:84px;
    }
    .text-ufuk {
      display: none;
    }
    a p {
      letter-spacing: -0.02em;
    }
  }
`;

H.Nav = styled.nav({
  opacity: 0,
  '& ul': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& li': {
    listStyle: 'none',
    marginRight: '4.3em',
    position: 'relative',
  },
  '& li:after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: 1,
    background: theme.colors.black,
    bottom: -10,
    maxWidth: 0,
    transition: 'max-width 400ms cubic-bezier(.14,.35,.51,.95)',
  },
  'header.light & li:after': {
    background: '#fff',
  },
  '& li.active:after': {
    maxWidth: '100%',
  },
  '& li:last-of-type': {
    marginRight: 0,
  },
  '& li.active a': {
    borderBottom: '1px solid',
  },
  'body.loaded &': {
    animation: `${navCome} 400ms cubic-bezier(.14,.35,.51,.95) forwards`,
  },
  'header.light & a p': {
    color: '#fff',
  },

  '@media (max-width:1024px)': {
    opacity: 1,
    '& ul li:first-of-type': {
      display: 'none',
    },
    '& li:not(:last-of-type)': {
      marginRight: '2em',
    },
  },
});
