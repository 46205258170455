import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const bounce = keyframes`
 50%{  max-height: 1.27em;}
 50.01%{  max-height: 1.27em;bottom:auto;top:-0.2em}
 100%{max-height:0;bottom:auto;top:-0.2em}
`;

const bounceBack = keyframes`
 0%{top:0;max-height:0}
 50%{top:0;max-height: 1.27em}
`;

const colorText = keyframes`
0%,50%{color:transparent}
 100%{color:#000}
`;

const colorTextLight = keyframes`
0%,50%{color:transparent}
 100%{color:#fff}
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 550;

  p {
    letter-spacing: 0.02em;
    line-height: 0.9;
    transition: color 100ms ease;
    position: relative;
    color: transparent;
    animation: ${colorText} 400ms ease forwards;
    animation-delay: 200ms;
    width: 95px;

    :after {
      content: '';
      display: block;
      position: absolute;
      top: 0.9em;
      left: 0;
      width: 100%;
      height: 2em;
      max-height: 0.17em;
      background-color: #000;
      animation: ${bounceBack} 600ms ease forwards;
    }
  }
  &.light p {
    animation: ${colorTextLight} 400ms ease forwards;
    :after {
      background-color: #fff;
    }
  }
  p.done {
    animation: ${colorText} 400ms reverse ease forwards;
    transition-delay: 100ms;
  }
  p.done:after {
    animation: ${bounce} 600ms ease forwards;
  }

  @media screen and (max-width: 600px) {
    p {
      width: 70px;
    }
  }
`;

export { LoadingContainer };
