import React, { useState } from 'react';

import Section from '../../components/Section/Section';
import Description from '../../components/Description';

import sections from '../../constants/sections';
import Content from '../../components/Content';
import Video from '../../components/Video';

import FullImage from '../../components/FullImage';
import { useWindowDimensions } from '../../utils/window';

import fontsImage from '../../assets/images/spitz/spitz-fonts.jpg';
import fontsMobileImage from '../../assets/images/spitz/spitz-fonts-mobile.jpg';
import webSite1Image from '../../assets/images/spitz/spitz-screens.jpg';
import webSite1MobileImage from '../../assets/images/spitz/spitz-screens-mobile.jpg';
import webAndMobileImage from '../../assets/images/spitz/spitz-mobile-screens.jpg';
import webAndMobileMobileImage from '../../assets/images/spitz/spitz-mobile-screens-mobile.jpg';
import devicesImage from '../../assets/images/spitz/spitz-devices.jpg';
import devicesMobileImage from '../../assets/images/spitz/spitz-devices-mobile.jpg';
import videoBgImage from '../../assets/images/spitz/spitz-video.jpg';
import videoBgMobileImage from '../../assets/images/spitz/spitz-video-mobile.jpg';
import phonesImage from '../../assets/images/spitz/spitz-phones.jpg';
import phonesMobileImage from '../../assets/images/spitz/spitz-phones-mobile.jpg';

import spitzVideo from '../../assets/videos/spitz.mp4';

import Footer from '../../components/Footer/Footer';

const SpitzGermany = () => {
  const name = 'SpitzGermany';
  const { description, mainColor } = sections[name];
  const [loadedImageCount, setLoadedImageCount] = useState(0);
  const { width } = useWindowDimensions();
  const itemCount = width < 768 ? 5 : 6;

  return (
    <Section
      sectionName={name}
      itemCount={itemCount}
      loadedImageCount={loadedImageCount}
    >
      <Description desc={description} />
      <div className='scroll-animation-elem both no-opacity' data-top='1' />
      <Content isShown={true} isFull={true} backgroundColor='#e6e6e6'>
        <FullImage
          mainColor={mainColor}
          image={fontsImage}
          mobileImage={fontsMobileImage}
          alt='Spitz fonts'
          loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
          className='scroll-animation-elem'
          data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        />
        <FullImage
          mainColor={mainColor}
          image={webSite1Image}
          mobileImage={webSite1MobileImage}
          alt='Spitz screens'
          loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
        />
        <FullImage
          mainColor={mainColor}
          image={webAndMobileImage}
          mobileImage={webAndMobileMobileImage}
          alt='Spitz web and mobile screens'
          loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
        />
        <FullImage
          mainColor={mainColor}
          image={devicesImage}
          mobileImage={devicesMobileImage}
          alt='Spitz web and mobile devices'
          loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
          // className='scroll-animation-elem'
          // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        />
        <Content backgroundColor={mainColor} isShown={true} isFull={true}>
          <Video
            image={videoBgImage}
            mobileImage={videoBgMobileImage}
            isFull
            video={spitzVideo}
            mobileVideo={spitzVideo}
            onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
            // className='scroll-animation-elem'
            // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
          />
        </Content>
        <FullImage
          mainColor={mainColor}
          image={phonesImage}
          mobileImage={phonesMobileImage}
          alt='Spitz app screens'
          loadCallBack={() => setLoadedImageCount((prevcount) => prevcount + 1)}
          // className='scroll-animation-elem'
          // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        />
      </Content>
      <Footer />
    </Section>
  );
};
export default SpitzGermany;
