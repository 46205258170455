import styled from '@emotion/styled';

export const B = {};
B.Container = styled.div(
  ({ maxHeight, backgroundColor, mobileFull, isFull, isBanner }) => {
    return {
      width: '100%',
      padding: isFull ? 0 : '0 60px',
      overflow: 'hidden',
      transition: 'max-height 500ms cubic-bezier(.04,.36,.24,.98)',
      backgroundColor: backgroundColor || 'transparent',
      ...(maxHeight ? { maxHeight: 0 } : ''),
      '#content-container.active-section &.shown': {
        height: 'auto',
        ...(maxHeight && isBanner ? { maxHeight: maxHeight } : ''),
        ...(!isBanner && { maxHeight: 'inherit' }),
      },
      '& .image-content > img': {
        width: '100%',
      },
      '@media (max-width:800px)': {
        padding: isFull ? 0 : mobileFull ? 0 : '0 30px',
      },
    };
  }
);
