import React from 'react';

import SocialIcon from '../SocialIcon/SocialIcon';
import { F } from './Footer.styles';

import ArrowRight from '../../assets/images/arrow-right-bold.svg';
import Text from '../Text/Text';
import theme from '../../theme';
import { Link } from 'gatsby';
import sections from '../../constants/sections';
import { useSelector } from 'react-redux';
import { selectSelectedSectionIndex } from '../../features/selectors';

import { addClass, removeClass } from '../../utils/evensts';

export default function Footer() {
  const selectedSectionIndex = useSelector(selectSelectedSectionIndex);

  const handleMouseEnter = () => {
    addClass('#content-body', 'next-hover');
  };

  const handleMouseLeave = () => {
    removeClass('#content-body', 'next-hover');
  };

  return (
    <>
      <F.Footer>
        <div>
          <SocialIcon social='behance' />
          <SocialIcon social='instagram' />
          <SocialIcon social='linkedin' />
          <SocialIcon social='mail' />
        </div>
        <div>
          <Link
            to='/Biography#bio__contact-list'
            background={theme.colors.white}
          >
            <Text size='lx' font='bold'>
              Get in touch
            </Text>
            <img src={ArrowRight} alt='arrow right' />
          </Link>
        </div>
      </F.Footer>
      <F.FakeNext
        onMouseEnter={(e) => handleMouseEnter()}
        onMouseLeave={(e) => handleMouseLeave()}
        onClick={() => removeClass('#content-body', 'next-hover')}
        to={`/${
          sections[Object.keys(sections)[selectedSectionIndex]].nextItem
        }`}
      />
    </>
  );
}
