import React, { useEffect, useRef } from 'react';

const Picture = ({
  mobile,
  desktop,
  alt,
  onLoad,
  pointkey = 600,
  ...props
}) => {
  const image = useRef();
  const handleLoad = (e) => setImageLoad(e);
  const setImageLoad = (e) => {
    onLoad(e);
  };

  useEffect(() => {
    if (image.current && image.current.complete) {
      setImageLoad();
    }
  }, []);

  return (
    <picture>
      <source media={`(max-width: ${pointkey}px)`} srcSet={mobile} />
      <source media={`(min-width: ${pointkey + 1}px)`} srcSet={desktop} />
      <img
        src={desktop}
        alt={alt}
        ref={image}
        onLoad={(e) => handleLoad(e)}
        {...props}
      />
    </picture>
  );
};

export default Picture;
