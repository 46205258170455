import React from 'react';
import { useSelector } from 'react-redux';
import { selectMainPageActiveIndex } from '../../features/selectors';
import { V } from './VerticalAnimationItem.styles';

export default function VerticalAnimationItem({
  sectionIndex,
  children,
  className,
  ...others
}) {
  const mainPageActiveIndex = useSelector(selectMainPageActiveIndex);

  const activeClass = mainPageActiveIndex === sectionIndex ? 'active' : '';
  const prevClass = mainPageActiveIndex > sectionIndex ? 'prev' : '';
  const nextClass = mainPageActiveIndex + 1 === sectionIndex ? 'next' : '';

  const resultClass = `${activeClass} ${prevClass} ${nextClass}`
    .replace(/\s+/g, ' ')
    .trim();
  return (
    <V.Container className={resultClass} {...others}>
      {children}
    </V.Container>
  );
}
