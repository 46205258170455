import { useState, useEffect } from 'react';

function WindowFn() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidthAndHeight);
    return () => window.removeEventListener('resize', updateWidthAndHeight);
  });

  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  if (typeof window !== 'undefined') return WindowFn();
  else return { width: 0, height: 0 };
}
