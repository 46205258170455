import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IMAGES_LOAD_AWAIT_LOADER_TIME } from '../../constants/common';
import {
  selectAllBannerImageLoadedCount,
  selectMainPageActiveIndex,
} from '../../features/selectors';
import {
  _handleAllImageDoneCallback,
  _updateMainPageLoaded,
} from '../../helpers/helper';
import { checkFreshpage } from '../../helpers/urlTracker';
import { addClass } from '../../utils/evensts';

import Text from '../Text/Text';

import { LoadingContainer } from './Loading.styles';

const Loading = ({
  itemCount,
  isContentLoader,
  mainPage,
  loadedImageCount,
}) => {
  const mainPageActiveIndex = useSelector(selectMainPageActiveIndex);
  const [count, setCount] = useState('00');
  const [done, setDone] = useState(false);
  let counter = 0;
  let maxPoint = 0;
  const waitTime = 300;
  const allBannerImageLoadedCount = useSelector(
    selectAllBannerImageLoadedCount
  );

  const setCounter = (itemCount, loadedImageCount) => {
    if (loadedImageCount < 1 || itemCount < loadedImageCount) {
      return;
    }
    if (loadedImageCount > itemCount) counter = 100;
    const unit = 100 / itemCount;
    maxPoint = unit * loadedImageCount;

    _setter();
  };

  function _setter() {
    setTimeout(() => {
      if (counter < 100) {
        if (counter > count) {
          let result = '00' + counter;

          setCount((prevState) => {
            // if (parseInt(prevState) + 1 < counter) return prevState;
            if (parseInt(prevState) > counter) return prevState;

            return result.slice(-2);
          });
        }

        counter++;
        if (counter < maxPoint) _setter();
      }
      if (counter > 99) {
        let result = '00' + 99;
        setCount(result.slice(-2));

        setTimeout(() => {
          setDone(true);
          if (isContentLoader) {
            setTimeout(
              () => _handleAllImageDoneCallback(true),
              IMAGES_LOAD_AWAIT_LOADER_TIME + 100
            );
          } else if (mainPage) {
            setTimeout(() => {
              _updateMainPageLoaded(true);
              checkFreshpage(mainPageActiveIndex);
              addClass('body', 'loaded');
            }, IMAGES_LOAD_AWAIT_LOADER_TIME + 100);
          }
        }, waitTime);
      }
    }, 25);
  }

  useEffect(() => {
    setCounter(itemCount, loadedImageCount);
  }, [loadedImageCount, itemCount]);

  return (
    <LoadingContainer>
      <Text
        size='lm'
        font='bold'
        id='loader-text'
        className={done ? 'done' : ''}
      >
        {count}
      </Text>
    </LoadingContainer>
  );
};

export default Loading;
