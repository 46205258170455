import styled from '@emotion/styled';

export const P = {};

P.Container = styled.div`
  width: 16px;
  position: absolute;
  top: 0;
  right: 22px;
  z-index: 999;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translate3d(60px, 0, 0);
  transition: transform 800ms cubic-bezier(0.77, 0, 0.175, 1);

  &.active {
    transform: translate3d(0, 0, 0);
  }
  @media screen and (max-width: 600px) {
    right: 7px;
    &.active {
      transform: translate3d(0, 37px, 0);
    }
  }
`;

P.PageLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #bfbfbf;
  margin-bottom: 20px;

  &:after {
    content: '';
    display: block;
    width: 100%;
    max-width: 0;
    background: #000;
    height: 2px;
    transition: max-width 800ms cubic-bezier(0.77, 0, 0.175, 1);
  }
  &.active:after {
    max-width: 20px;
  }

  @media screen and (max-width: 800px) {
    background-color: #000;
    margin-bottom: 19px;
    &:after {
      background: #fff;
    }
  }
`;
