import styled from '@emotion/styled';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: auto;
  transition: padding 500ms cubic-bezier(0.04, 0.36, 0.24, 0.98);
  background-color: #fff;

  #content-container.active-section &.shrink {
    padding: 0 60px;
  }

  @media screen and (max-width: 800px) {
    #content-container.active-section &.shrink {
      padding: 0;
    }
  }
`;

const Banner = styled.div(() => {
  return {
    width: '100%',
    height: '100%',
    position: 'relative',
    transition: 'height 300ms cubic-bezier(.44,.09,.51,.95)',
    '.section.loaded &': {
      transition: 'height cubic-bezier(.44,.09,.51,.95)',
    },
    '& .gatsby-image-wrapper': {
      height: '100%',
    },
    'div.ready + &': {
      height: 0,
      paddingBottom: '50%',
      '& > a': {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
      },
    },
    '& img': {
      width: '100%',
      height: '100%',
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      objectFit: 'cover',
      objectPosition: 'center top!important',
    },
    // "@media (max-width:800px)": {
    //   paddingTop: 54,
    // },
  };
});

export { Container, Banner };
