function hasClass(ele, cls) {
  if (ele.getAttribute('class') === null) {
    ele.setAttribute('class', '');
  }
  return !!ele
    .getAttribute('class')
    .match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

function addClass(ele, cls) {
  var array;
  if (typeof ele == 'string') {
    array = document.querySelectorAll(ele);
    for (var index = 0; index < array.length; index++) {
      var element = array[index];
      if (!hasClass(element, cls)) {
        var clsName = element.getAttribute('class').replace(/\s+/g, ' ').trim();
        element.setAttribute('class', clsName + ' ' + cls.trim());
      }
    }
  } else {
    if (!hasClass(ele, cls)) {
      var clsName = ele.className;
      ele.getAttribute('class').replace(/\s+/g, ' ').trim();

      var className = typeof clsName === 'object' ? clsName.baseVal : clsName;
      ele.setAttribute('class', className + ' ' + cls.trim());
    }
  }
}

function removeClass(ele, cls) {
  var array;
  if (typeof ele == 'string') {
    array = document.querySelectorAll(ele);
    for (var index = 0; index < array.length; index++) {
      var element = array[index];
      var classes = cls.split(' ');

      for (var i = 0; i < classes.length; i++) {
        var c = classes[i];
        if (hasClass(element, c)) {
          var reg = new RegExp('(\\s|^)' + c + '(\\s|$)');
          //element.className = element.className.replace(reg, " ");
          element.setAttribute(
            'class',
            element.getAttribute('class').replace(reg, ' ')
          );
        }
      }
    }
  } else if (typeof ele.length !== 'undefined') {
    for (var i = 0; i < ele.length; i++) {
      var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
      ele[i].setAttribute(
        'class',
        ele[i].getAttribute('class').replace(reg, ' ')
      );
    }
  } else {
    if (hasClass(ele, cls)) {
      var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
      ele.setAttribute('class', ele.getAttribute('class').replace(reg, ' '));
    }
  }
}

const isBrowser = () => typeof window !== 'undefined';

export { hasClass, removeClass, addClass, isBrowser };
