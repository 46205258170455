import styled from '@emotion/styled';

export const V = {};

V.Container = styled.div`
  position: absolute;
  transition-delay: 0;
  transform: translate3d(0, 30%, 0);
  opacity: 0;
  z-index: 500;
  #numbers & {
    transform: translate3d(0, 0, 0);
  }
  &.active {
    transform: translate3d(0, 0, 0);
    z-index: 600;
    opacity: 1;
    transition-delay: 0.2s !important;
    transition: transform 0.3s cubic-bezier(0.2, 0.38, 0.57, 0.89),
      opacity 0.3s cubic-bezier(0.2, 0.38, 0.57, 0.89);
  }
  &.prev {
    transform: translate3d(0, -30%, 0);
    z-index: 600;
    transition-delay: 0s !important;
    transition: transform 0.3s cubic-bezier(0.35, 0.12, 0.85, 0.63),
      opacity 0.3s cubic-bezier(0.35, 0.12, 0.85, 0.63);
  }
  &.next {
    transform: translate3d(0, 30%, 0);
    z-index: 500;
    transition-delay: 0s !important;
    transition: transform 0.3s cubic-bezier(0.35, 0.12, 0.85, 0.63),
      opacity 0.3s cubic-bezier(0.35, 0.12, 0.85, 0.63);
  }
`;
