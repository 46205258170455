import React from 'react';

import sections from '../../constants/sections';

import Link from '../Link/Link';
import Text from '../Text/Text';

import theme from '../../theme';
import { N } from './NextSection.styles';

import arrowWhite from '../../assets/images/arrow-right-bold-white.svg';

export default function NextSection({
  name,
  themeType = 'dark',
  withoutText,
  marginTop,
  ...props
}) {
  const item = sections[name];

  return (
    <N.Content
      className='next-section-badge'
      {...props}
      marginTop={marginTop}
      id='container__next-elem'
    >
      <N.Container background={item.color}>
        <Link
          to={`/${item.name}`}
          className='section-link'
          onClick={() => {
            document
              .querySelectorAll('.section.active .section-banner')[0]
              .setAttribute('style', '');
            document
              .querySelectorAll('.section.active')[0]
              .setAttribute('style', '');
          }}
        >
          <img src={item.nextImage} alt={item.name} />
          <div>
            <Text
              size='lmx'
              as='h4'
              font='bold'
              color={
                themeType === 'dark' ? theme.colors.black : theme.colors.white
              }
            >
              Next{' '}
              <Text as='span' size='lmx' font='bold'>
                Project
              </Text>
              <img src={arrowWhite} alt='arrow right' />
            </Text>
            <Text
              size='md'
              color={
                themeType === 'dark' ? theme.colors.black : theme.colors.white
              }
            >
              {item.title}
            </Text>
          </div>
        </Link>
      </N.Container>
      <N.Shade />
    </N.Content>
  );
}
