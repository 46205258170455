import React, { useEffect, useState } from 'react';

import Section from '../../components/Section/Section';
import Description from '../../components/Description';

import sections from '../../constants/sections';
import Content from '../../components/Content';
import Picture from '../../components/Picture';
import Video from '../../components/Video';
import Footer from '../../components/Footer/Footer';

import { C } from '../Containers.styles';

import cpWebSite from '../../assets/images/cp/cp-website.jpg';
import cpWebSiteMobile from '../../assets/images/cp/cp-website-mobile.jpg';
import cpApp from '../../assets/images/cp/cp-app.jpg';
import cpAppMobile from '../../assets/images/cp/cp-app-mobile.jpg';
import cpAppScreens from '../../assets/images/cp/cp-app-screens.jpg';
import cpAppScreensMobile from '../../assets/images/cp/cp-app-screens-mobile.jpg';

import cpAppScreensNext from '../../assets/images/cp/cp-app-screens-next.jpg';
import cpAppScreensNextMobile from '../../assets/images/cp/cp-app-screens-next-mobile.jpg';
import cpComponentImage from '../../assets/images/cp/cp-components.jpg';
import cpComponentMobileImage from '../../assets/images/cp/cp-components-mobile.jpg';
import cpScreensVerticalImage from '../../assets/images/cp/cp-screens-vertical.jpg';
import cpScreensVerticalMobileImage from '../../assets/images/cp/cp-screens-vertical-mobile.jpg';

import cpVideo from '../../assets/videos/cp.mp4';

import { _returnUrlSectionIndex } from '../../helpers/helper';

import { useSelector } from 'react-redux';
import {
  addClass,
  hasClass,
  isBrowser,
  removeClass,
} from '../../utils/evensts';
import {
  selectMainPageActiveIndex,
  selectMainPageScrolldirection,
  selectSelectedSectionIndex,
} from '../../features/selectors';
import { useWindowDimensions } from '../../utils/window';

const LorealContentPro = () => {
  const name = 'LorealContentPro';
  const { description, mainColor } = sections[name];
  const [loadedImageCount, setLoadedImageCount] = useState(0);
  const mainPageActiveIndex = useSelector(selectMainPageActiveIndex);
  const mainPageScrolldirection = useSelector(selectMainPageScrolldirection);
  const selectedSectionIndex = useSelector(selectSelectedSectionIndex);
  const { width } = useWindowDimensions();
  const itemCount = width < 768 ? 6 : 7;

  useEffect(() => {
    setLoadedImageCount(0);
  }, [selectedSectionIndex]);

  useEffect(() => {
    if (
      mainPageActiveIndex < 2 &&
      selectedSectionIndex === -1 &&
      !hasClass(document.getElementById('content-body'), 'return-work') &&
      _returnUrlSectionIndex() < 1
    ) {
      addClass('#content-container > .section.first-section', 'bottom-to-top');
      if (!isBrowser) return;
      if (window.innerWidth < 800) {
        const el = document.querySelectorAll(
          '#content-container > .section.first-section.bottom-to-top > div:nth-of-type(2)'
        );

        if (el.length > 0) {
          el[0].style.height =
            document.querySelectorAll(
              '#content-container > .section:nth-of-type(3)'
            )[0].offsetHeight + 'px';
        }
      }
    } else {
      const el = document.querySelectorAll(
        '#content-container > .section.first-section.bottom-to-top > div:nth-of-type(2)'
      );

      if (el.length > 0) el[0].setAttribute('style', '');
      removeClass(
        '#content-container > .section.first-section',
        'bottom-to-top'
      );
    }
  }, [mainPageScrolldirection]);

  const handleTrantsitionEnd = (e) => {
    if (
      mainPageActiveIndex < 1 &&
      selectedSectionIndex === -1 &&
      !hasClass(document.getElementById('content-body'), 'return-work')
    ) {
      return false;
    } else {
      removeClass(
        '#content-container > .section.first-section',
        'bottom-to-top'
      );

      document
        .querySelectorAll(
          '#content-container > .section.first-section > div:nth-of-type(2)'
        )[0]
        .setAttribute('style', '');
    }
  };

  return (
    <>
      <Section
        sectionName={name}
        itemCount={itemCount}
        loadedImageCount={loadedImageCount}
        className={`first-section ${
          mainPageScrolldirection === 'down' && mainPageActiveIndex === 1
            ? 'down'
            : ''
        }`}
        onTransitionEnd={handleTrantsitionEnd}
      >
        <Description
          desc={description}
          // className='header-scroll-point header-light'
        />
        <Content
          isShown={true}
          isFull={true}
          className='scroll-animation-elem both no-opacity header-scroll-point header-light'
          data-top='1'
          backgroundColor='#3e1b43'
          // data-animate='{"targets":".section.selected","background":"#3f1d44", "duration": 800}'
          // data-reverse-animate='{"targets":".section.selected","background":"#fff", "duration": 800}'
        >
          <C.FullImage>
            <Picture
              mobile={cpWebSiteMobile}
              desktop={cpWebSite}
              alt='ContentPro Web Site'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              className='scroll-animation-elem'
              data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.FullImage>
        </Content>
        <Content backgroundColor={mainColor} isShown={true} isFull={true}>
          <C.FullImage>
            <Picture
              mobile={cpAppMobile}
              desktop={cpApp}
              alt='ContentPro App Screens'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              // className='scroll-animation-elem'
              // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.FullImage>
        </Content>
        <Content backgroundColor={mainColor} isShown={true} isFull={true}>
          <C.FullImage>
            <Picture
              mobile={cpAppScreensMobile}
              desktop={cpAppScreens}
              alt='ContentPro App Screens'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              // className='scroll-animation-elem'
              // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.FullImage>
        </Content>
        <Content backgroundColor='#401a43' isShown={true} isFull={true}>
          <Video
            // image={cpVideoImage}
            // mobileImage={cpVideoMobileImage}
            isFull
            video={cpVideo}
            onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
            backgroundColor='#401a43'
            mobileVideo={cpVideo}
            // className='scroll-animation-elem'
            // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
          />
        </Content>
        <Content backgroundColor={mainColor} isShown={true} isFull={true}>
          <C.FullImage>
            <Picture
              mobile={cpAppScreensNextMobile}
              desktop={cpAppScreensNext}
              alt='ContentPro App Screens'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              // className='scroll-animation-elem'
              // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.FullImage>
        </Content>
        <Content backgroundColor={mainColor} isShown={true} isFull={true}>
          <C.FullImage>
            <Picture
              mobile={cpComponentMobileImage}
              desktop={cpComponentImage}
              alt='ContentPro App Components'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              // className='scroll-animation-elem'
              // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.FullImage>
        </Content>
        <Content backgroundColor={mainColor} isShown={true} isFull={true}>
          <C.FullImage>
            <Picture
              mobile={cpScreensVerticalMobileImage}
              desktop={cpScreensVerticalImage}
              alt='ContentPro App Screens'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              // className='scroll-animation-elem'
              // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.FullImage>
        </Content>
        <Footer />
      </Section>
    </>
  );
};
export default LorealContentPro;
