import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import theme from '../../theme';

export const V = {};

V.Container = styled.div(({ backgroundColor }) => {
  return {
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
    backgroundColor: backgroundColor || 'transparent',
  };
});

V.ImageContainer = styled.div(({ maxImageWidth, isFull }) => {
  return {
    position: 'relative',
    width: '100%',
    margin: 'auto',
    padding: isFull ? 0 : '0 60px',
    ...(maxImageWidth ? { maxWidth: maxImageWidth } : ''),
    left: 0,
    right: 0,
    zIndex: 10,
    '& p': {
      width: '100%',
      marginTop: 0,
      textAlign: 'center',
    },

    '& img': {
      width: '100%',
      position: 'relative',
      zIndex: 10,
      display: 'block',
    },
    '& .gatsby-image-wrapper': {
      zIndex: 5,
    },
  };
});

V.VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    width: 100%;
  }
`;

V.Play = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: ${({ $active }) => ($active ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;
  img {
    width: 60px;
  }
`;

const rotation = keyframes`
  0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

V.Waiter = styled.div`
  opacity: 0.5;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10px;
  bottom: 10px;
  border: 2px solid ${theme.colors.white};
  border-top-color: transparent;
  border-radius: 50%;
  animation: ${rotation} 1s linear infinite;
`;
