import styled from '@emotion/styled';

export const C = {};

C.Container = styled.div(({ maxWidth }) => {
  return {
    maxWidth: 0,
    overflow: 'hidden',
    paddingTop: 191,
    transition: 'max-width 650ms ease-out',
    transitionDelay: '500ms',
    '&.active': {
      maxWidth: maxWidth + 50,
    },
    '& > div': {
      minWidth: '100vw',
    },
    '& p': {
      letterSpacing: '-0.025em',
      display: 'inline-block',
    },
    '& h2': {
      margin: '30px 0 50px 0',
      display: 'inline-block',
    },
    '@media (max-width:800px)': {
      paddingTop: 176,

      '& h2': {
        margin: '20px 0 40px 0',
        maxWidth: '95%',
      },
      '& > div': {
        minWidth: 'calc(100vw - 60px)',
      },
    },
  };
});
