import React, { useState } from 'react';

import Section from '../../components/Section/Section';
import Description from '../../components/Description';
import sections from '../../constants/sections';
import Content from '../../components/Content';
import Picture from '../../components/Picture';
import Video from '../../components/Video';

import { ContentText } from '../../components/ContentText/ContentText';

import { C } from '../Containers.styles';

import wfaImage from '../../assets/images/wamo/wamo-firm-andshake.jpg';
import whfImage from '../../assets/images/wamo/wamo-high-five.jpg';
import webPagesImage from '../../assets/images/wamo/wamo-web-pages.jpg';
import webPagesMobileImage from '../../assets/images/wamo/wamo-web-pages-mobile.jpg';
import wamoTextImage from '../../assets/images/wamo/wamo-text.jpg';
import wamoTextMobileImage from '../../assets/images/wamo/wamo-text-mobile.jpg';
import mobileScreens from '../../assets/images/wamo/wamo-screens.jpg';
import mobileMobileScreens from '../../assets/images/wamo/wamo-screens-mobile.jpg';
import wunybImage from '../../assets/images/wamo/wamo-unlimit-your-business.jpg';
import wdpmfmImage from '../../assets/images/wamo/wamo-dont-pay-more-for-more.jpg';
import micMobileImage from '../../assets/images/wamo/wamo-mic-mobile.jpg';
import micImage from '../../assets/images/wamo/wamo-mic.jpg';

import Footer from '../../components/Footer/Footer';
import Image from '../../components/Image/Image';

import desktopVideo from '../../assets/videos/wamo_big.mp4';
import mobileVideo from '../../assets/videos/wamo_mobile750.mp4';

import { useWindowDimensions } from '../../utils/window';

const Wamo = () => {
  const name = 'Wamo';
  const { description } = sections[name];
  const [loadedImageCount, setLoadedImageCount] = useState(0);
  const { width } = useWindowDimensions();
  const itemCount = width < 768 ? 8 : 9;

  return (
    <Section
      sectionName={name}
      itemCount={itemCount}
      loadedImageCount={loadedImageCount}
    >
      <Description desc={description} />
      <Content isShown={true} mobileFull={true}>
        <Video
          // image={cpVideoImage}
          // mobileImage={cpVideoMobileImage}
          backgroundColor='#e8e8e8'
          isFull
          video={width < 769 ? mobileVideo : desktopVideo}
          mobileVideo={width < 769 ? mobileVideo : desktopVideo}
          onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
          // className='scroll-animation-elem'
          // data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        />
      </Content>
      <Content isShown={true} mobileFull={true}>
        <C.HalfParent>
          <C.Half>
            <Image
              src={wfaImage}
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              alt='Wamo pages'
              className='scroll-animation-elem'
              data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.Half>
          <C.Half>
            <Image
              src={whfImage}
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              alt='Wamo pages'
              className='scroll-animation-elem'
              data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.Half>
        </C.HalfParent>
      </Content>
      <Content isShown={true} mobileFull={true}>
        <C.FullImage>
          <Picture
            mobile={webPagesMobileImage}
            desktop={webPagesImage}
            alt='Wamo screen'
            onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
            className='scroll-animation-elem'
            data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
          />
        </C.FullImage>
      </Content>
      <Content isShown={true} mobileFull={true}>
        <C.HalfParent>
          <ContentText title='DEBIT CARDS'>
            Wamo debit cards are designed with the same illustrations as the
            website, creating a young and unconventional look. Users can
            customize their card with 6 different illustrations to choose from.
          </ContentText>
          <C.Half>
            <Picture
              mobile={wamoTextMobileImage}
              desktop={wamoTextImage}
              alt='Wamo screen and card'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              className='scroll-animation-elem'
              data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.Half>
        </C.HalfParent>
      </Content>
      <Content isShown={true} mobileFull={true}>
        <C.FullImage>
          <Picture
            mobile={mobileMobileScreens}
            desktop={mobileScreens}
            alt='Wamo screen'
            onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
            className='scroll-animation-elem'
            data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
          />
        </C.FullImage>
      </Content>
      <Content isShown={true} mobileFull={true}>
        <C.HalfParent>
          <C.Half>
            <Image
              src={wunybImage}
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              className='scroll-animation-elem'
              data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
              alt='Wamo pages'
            />
          </C.Half>
          <C.Half>
            <Image
              src={wdpmfmImage}
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              alt='Wamo pages'
              className='scroll-animation-elem'
              data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.Half>
        </C.HalfParent>
      </Content>
      <Content isShown={true} mobileFull={true}>
        <C.FullImage>
          <Picture
            mobile={micMobileImage}
            desktop={micImage}
            alt='Wamo screen'
            onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
            className='scroll-animation-elem'
            data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
          />
        </C.FullImage>
      </Content>
      <Footer />
    </Section>
  );
};
export default Wamo;
