import React from 'react';
import socials from '../../constants/social';
import Link from '../Link/Link';
import { S } from './SocialIcon.styles';

export default function SocialIcon({ social }) {
  const item = socials[social];
  return (
    <S.Container>
      <Link isExternal={true} to={item.to}>
        <img src={item.icon} alt={item.icon} />
      </Link>
    </S.Container>
  );
}
