import { hasClass } from './evensts';

function wrapTextToSpan(e, t) {
  if (e.innerHTML) {
    var n =
      '<span>' +
      (n = getWrappedText(e, e.innerText)).replace(/\n/g, '</span><span> ') +
      '</span>';

    e.innerHTML = t ? e.innerHTML : n;
  }
}

function getWrappedText(e, t) {
  var i = document.createElement('textarea');
  /* eslint-disable no-unused-expressions */
  e.appendChild(i),
    (i.style.width = e.offsetWidth + 2 + 'px'),
    (i.style.position = 'absolute'),
    (i.className = e.className),
    (i.wrap = 'off');
  for (
    var n, a = t.replace(/\n/g, '').split(/\s/gi), s = i.scrollWidth, o = 0;
    o < a.length;
    o++
  )
    /* eslint-disable no-unused-expressions */
    a[o].length < 1 ||
      ((n = a[o] + ' '),
      (i.value += n),
      i.scrollWidth > s &&
        (i.value = i.value.slice(0, i.value.length - n.length - 1) + '\n' + n));

  return e.removeChild(i), i.value;
}

function _spanToText(par) {
  if (hasClass(par, 'full')) return false;
  var els = par.querySelectorAll('p');

  for (var i = 0; i < els.length; i++) {
    var spans = els[i].querySelectorAll('span');
    var txt = '';
    for (var s = 0; s < spans.length; s++) {
      txt += spans[s].innerText + ' ';
    }

    if (txt != '') els[i].innerHTML = txt;
  }
}

export { wrapTextToSpan, _spanToText };
