import colors from "./colors"
import fonts from "./fonts"
import spacing from "./spacing"

const theme = {
  colors,
  fonts,
  spacing,
}

export default theme
