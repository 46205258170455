const selectAnimations = (state) => state.animations;

export const selectMainPageActiveIndex = (state) =>
  selectAnimations(state).mainPageActiveIndex;

export const selectSelectedSectionIndex = (state) =>
  selectAnimations(state).selectedSectionIndex;

export const selectIsBioPageOpen = (state) =>
  selectAnimations(state).isBioPageOpen;

export const selectIsHomeTextGone = (state) =>
  selectAnimations(state).isHomeTextGone;

export const selectIsMainLoaded = (state) =>
  selectAnimations(state).isMainLoaded;

export const selectMainPageScrolldirection = (state) =>
  selectAnimations(state).mainPageScrolldirection;

export const selectIsBannerRestShown = (state) =>
  selectAnimations(state).isBannerRestShown;

export const selectIsDetailHeaderAndContentShown = (state) =>
  selectAnimations(state).isDetailHeaderAndContentShown;

export const selectIsHeaderBgGone = (state) =>
  selectAnimations(state).isHeaderBgGone;

export const selectAllBannerImageLoadedCount = (state) =>
  selectAnimations(state).allBannerImageLoadedCount;

export const selectIsAllSectionGoneButSelected = (state) =>
  selectAnimations(state).isAllSectionGoneButSelected;

export const selectIsImagesLoading = (state) =>
  selectAnimations(state).isImagesLoading;
