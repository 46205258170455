import React, { useState } from 'react';

import Section from '../../components/Section/Section';
import Description from '../../components/Description';

import sections from '../../constants/sections';
import Content from '../../components/Content';
import Picture from '../../components/Picture';
import Footer from '../../components/Footer/Footer';
import Video from '../../components/Video';

import devicesImage from '../../assets/images/fp/devices.png';
import devicesMobileImage from '../../assets/images/fp/devices-mobile.png';
import wsTopImage from '../../assets/images/fp/web-site-top.jpg';
import wsTopMobileImage from '../../assets/images/fp/web-site-top-mobile.jpg';
import wsMiddleImage from '../../assets/images/fp/web-site-middle.jpg';
import wsMiddleMobileImage from '../../assets/images/fp/web-site-middle.jpg';
import wsBottomImage from '../../assets/images/fp/web-site-bottom.jpg';
import wsBottomMobileImage from '../../assets/images/fp/web-site-bottom-mobile.jpg';
import screenTopImage from '../../assets/images/fp/screens-top.jpg';
import screenBottomImage from '../../assets/images/fp/screens-bottom.jpg';
import screenBottomMobileImage from '../../assets/images/fp/screens-bottom-mobile.jpg';
import carouselTopImage from '../../assets/images/fp/caruosel-top.jpg';
import carouselTopMobileImage from '../../assets/images/fp/caruosel-top-mobile.jpg';
import carouselBottomImage from '../../assets/images/fp/caruosel-bottom.jpg';
import carouselBottomMobileImage from '../../assets/images/fp/caruosel-bottom-mobile.jpg';

import desktopVideo from '../../assets/videos/fifth-desktop.mp4';
import mobileVideo from '../../assets/videos/fifth-mobile.mp4';

import { F } from './FifthPosition.styles';
import { useWindowDimensions } from '../../utils/window';

const FifthPosition = () => {
  const name = 'FifthPosition';
  const { description, mainColor, secondaryColor } = sections[name];
  const [loadedImageCount, setLoadedImageCount] = useState(0);
  const { width } = useWindowDimensions();
  const itemCount = width < 768 ? 8 : 10;

  return (
    <Section
      sectionName={name}
      itemCount={itemCount}
      loadedImageCount={loadedImageCount}
    >
      <Description desc={description} />
      <Content
        backgroundColor={mainColor}
        isShown={true}
        isFull={true}
        className='both no-opacity'
        data-top='1'
      >
        <F.Devices className='image-content'>
          <Picture
            mobile={devicesMobileImage}
            desktop={devicesImage}
            alt='Fifth Position website on devices'
            onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
            data-top='0.8'
            className='scroll-animation-elem'
            data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
          />
        </F.Devices>
      </Content>
      <div>
        <Content backgroundColor={mainColor} isShown={true} isFull={true}>
          <F.WebSiteTop className='image-content'>
            <Picture
              mobile={wsTopMobileImage}
              desktop={wsTopImage}
              alt='Fifth Position website'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
            />
          </F.WebSiteTop>
        </Content>
        <Content backgroundColor={mainColor} isShown={true} isFull={true}>
          <F.WebSite className='image-content'>
            <Picture
              mobile={wsMiddleMobileImage}
              desktop={wsMiddleImage}
              alt='Fifth Position website'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
            />
          </F.WebSite>
        </Content>
        <Content backgroundColor={mainColor} isShown={true} isFull={true}>
          <F.WebSiteBottom className='image-content'>
            <Picture
              mobile={wsBottomMobileImage}
              desktop={wsBottomImage}
              alt='Fifth Position website'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
            />
          </F.WebSiteBottom>
        </Content>
      </div>
      <Content backgroundColor={secondaryColor} isShown={true} isFull={true}>
        <F.FirstVideo>
          <Video
            isFull
            video={desktopVideo}
            mobileVideo={desktopVideo}
            onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
          />
        </F.FirstVideo>
      </Content>
      <Content isFull backgroundColor={secondaryColor}>
        <div>
          <Content
            backgroundColor={secondaryColor}
            isShown={true}
            isFull={true}
          >
            <F.Screens className='image-content'>
              <Picture
                mobile={screenTopImage}
                desktop={screenTopImage}
                alt='Fifth Position website pages'
                onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              />
            </F.Screens>
          </Content>
          <Content
            backgroundColor={secondaryColor}
            isShown={true}
            isFull={true}
          >
            <F.ScreensBottom className='image-content'>
              <Picture
                mobile={screenBottomMobileImage}
                desktop={screenBottomImage}
                alt='Fifth Position website pages'
                onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              />
            </F.ScreensBottom>
          </Content>
        </div>
      </Content>
      <Content backgroundColor={mainColor} isShown={true} isFull={true}>
        <Video
          isFull
          video={mobileVideo}
          mobileVideo={mobileVideo}
          backgroundColor='#fccdbe'
          onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
        />
      </Content>
      <div>
        <Content backgroundColor={secondaryColor} isShown={true} isFull={true}>
          <F.Screens className='image-content'>
            <Picture
              mobile={carouselTopMobileImage}
              desktop={carouselTopImage}
              alt='Fifth Position website pages on mobile devices'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
            />
          </F.Screens>
        </Content>
        <Content backgroundColor={secondaryColor} isShown={true} isFull={true}>
          <F.ScreensCaruosel className='image-content'>
            <Picture
              mobile={carouselBottomMobileImage}
              desktop={carouselBottomImage}
              alt='Fifth Position website pages on mobile devices'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
            />
          </F.ScreensCaruosel>
        </Content>
      </div>
      <Footer />
    </Section>
  );
};

export default FifthPosition;
