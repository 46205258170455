import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import ReactScrollWheelHandler from 'react-scroll-wheel-handler';

import theme from '../theme';

export const C = {};

const fades = keyframes`
  20%{
    opacity: 0;
  }

  99%{
    opacity: 0;
  }
  99.5%{
    opacity: 1;
  }
`;

C.ContentBody = styled.div`
  min-height: 100%;
  background: ${theme.colors.cream};
  transition: transform 600ms cubic-bezier(0.77, 0, 0.175, 1),
    opacity 600ms cubic-bezier(0.77, 0, 0.175, 1);

  &.slided {
    /* transform: translate3d(0, -20%, 0); */
    pointer-events: none;
    opacity: 0;
  }
  &.fadeInOut {
    animation: ${fades} 1s forwards;
  }
  &.disabled {
    pointer-events: none;
  }
  &.return-work .section:not(.active) {
    opacity: 0;
    pointer-events: none;
  }
  @media (max-width: 800px) {
    min-height: 100%;
  }
`;

C.SectionContainer = styled(ReactScrollWheelHandler)({
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 1,

  '#content-box:not(.detail-page ) &': {
    background: '#F8F7F5',
  },

  '&:after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: 60,
    bottom: 0,
    left: 0,
    background: theme.colors.cream,
    zIndex: 800,
    transition: 'transform 800ms cubic-bezier(0.77, 0, 0.175, 1)',
  },
  'body:not(.loaded) &:after, body.bio-page &:after,body.animate-home &:after':
    {
      transform: 'translate3d(0,100%,0)',
    },

  '&.active-section:after': {
    transform: 'translate3d(0,100%,0)',
  },
  '&.active-section .section:not(.selected)': {
    opacity: 0,
    pointerEvents: 'none',
  },

  '@media (max-width:800px)': {
    '&:after': { display: 'none' },
  },
});

C.ReadButton = styled.div({
  position: 'absolute',
  zIndex: 90,
  height: 60,
  width: 238,
  left: 102,
  bottom: 60,
  maxWidth: 300,
  display: 'flex',
  justifyContent: 'flex-start',

  '& button': {
    width: 238,
    flexShrink: 0,
    background: theme.colors.cream,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& *': { pointerEvents: 'none' },
    '& span:nth-of-type(2)': {
      display: 'none',
    },
    '& img': {
      width: 52,
      marginLeft: 31,
    },
  },
  '& > div': {
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'flex-start',
    transition: 'max-width 400ms cubic-bezier(0.77, 0, 0.175, 1)',
  },
  '&.down > div': {
    transitionDelay: '200ms',
  },

  '&.gone': { pointerEvents: 'none' },

  '&.gone > div': {
    maxWidth: 0,
  },
  '@media (max-width:800px)': {
    bottom: 0,
    left: 30,
  },
  '@media (max-width:600px)': {
    width: 178,
    left: 0,

    '& button': {
      width: 178,
      paddingLeft: 30,
      '& span:nth-of-type(1)': {
        display: 'none',
      },
      '& span:nth-of-type(2)': {
        display: 'block',
      },
      '& img': {
        width: 40,
        marginLeft: 20,
      },
    },
  },
});

C.FullImage = styled.div({
  width: '100%',
  display: 'flex',
  '& img': {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
});

C.HalfParent = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 30,
  '@media (max-width:1024px)': {
    flexDirection: 'column',
  },
  '@media (max-width:600px)': {
    marginTop: 15,
    '& .text-half:first-of-type': {
      marginBottom: 70,
      marginTop: 55,
    },
  },
});

C.Half = styled.div({
  width: 'calc(50% - 15px)',
  marginBottom: 30,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  '&.left-side-padding': {
    paddingLeft: 75,
  },
  '& p': {
    width: '82%',
    marginTop: 50,
  },
  '& img': {
    display: 'block',
    width: '100%',
  },
  '&.text-half.right': {
    paddingLeft: 50,
  },
  '@media (max-width:1150px)': {
    '&.left-side-padding': {
      paddingLeft: 55,
    },
  },

  '@media (max-width:1024px)': {
    width: '100%',
    '&.text-half': {
      padding: '0 30px',
    },
    '& p': {
      width: '100%',
    },
    '&.left-side-padding': {
      padding: '0 30px',
    },
  },
  '@media (max-width:600px)': {
    marginBottom: 15,
    '&.text-half.right': {
      paddingLeft: 30,
    },
    '&.left-side-padding': {
      padding: '0 30px',
      marginBottom: 55,
    },
  },
});

C.LastItem = styled.div({
  marginBottom: 30,
  '@media (max-width:600px)': {
    marginBottom: 15,
  },
});

C.Description = styled.div({
  width: '100%',
  maxWidth: 870,
  margin: 'auto',
  padding: '120px 0',
  '& p': {
    marginTop: 50,
  },
  '@media (max-width:800px)': {
    padding: '70px 0',
  },
});

C.FullHeight = styled.div`
  height: 100%;
  &#content-box {
    background-color: ${theme.colors.cream};
  }
`;
