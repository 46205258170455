import styled from '@emotion/styled';
import theme from '../../theme';

export const B = {};

B.Content = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  transform: translate3d(0, 100%, 0);
  pointer-events: none;
  transition-delay: 200ms;
  &.active {
    transform: translate3d(0, 0%, 0);
    pointer-events: all;
    overflow: auto;
  }
`;

B.Container = styled.div`
  overflow: auto;
  margin-top: 239px;
  margin-left: 113px;
  width: 100%;
  max-width: 1102px;
  padding: 0 60px;

  & h2 {
    margin-bottom: 0.54em;
    opacity: 0;
    transform: translate3d(0, 40%, 0);
  }
  & h2.bio-first {
    transition: transform 600ms ease-out, opacity 600ms ease-out;
  }
  body.bio-page & h2:not(#bio__contact-list-header) {
    /* transition-delay: 600ms !important; */
  }

  #bio-content.active & h2.bio-first {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  & h2.wider {
    margin-left: -0.06em;
  }

  & h2.last {
    margin-bottom: 0.57em;
  }

  span:not(.featured-url) {
    font-family: Helvetica N Roman;
    opacity: 0;
    display: inline-block;
  }

  @media screen and (max-width: 1300px) {
    margin-left: 0;
  }

  @media screen and (max-width: 600px) {
    & h2.bio-first {
      transition: transform 400ms ease-out, opacity 400ms ease-out;
    }
    margin-top: 32vh;
    padding: 0 30px;
    & h2 {
      font-size: 84px;
      margin-bottom: 50px;
      letter-spacing: -0.05em;
      line-height: normal;
    }
  }
`;

B.Part = styled.div`
  margin-top: 120px;
  @media screen and (max-width: 600px) {
    margin-top: 80px;
  }
`;

B.AwardRow = styled.div`
  display: flex;
  padding-bottom: 33px;
  margin-bottom: 33px;
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #e5e2de;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 0;
    transition: max-width 600ms cubic-bezier(0.61, 1, 0.88, 1);
    transition-delay: 200ms;
  }
  &.animated::after {
    max-width: 100%;
  }
  &.double > div:first-of-type {
    width: 33%;
    flex: inherit;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
    :after {
      bottom: 30px;
      display: none;
    }
    &.double > div:first-of-type {
      width: 100%;
      flex: 1;
    }
    .bio-contact-header {
      margin-left: -5px;
    }
  }
`;

B.Award = styled.div`
  flex: 1;
  & h4 {
    margin-bottom: 20px;
  }
  & h4 span {
    vertical-align: top;
    padding-left: 5px;
    opacity: 1;
    letter-spacing: -0.01em;
  }
  & p {
    line-height: 1.3;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e5e2de;
    & h4 {
      font-size: 32px;
      line-height: 32px;
      letter-spacing: -0.01em;
      margin-bottom: 16px;
      span {
        font-weight: bold;
      }
    }
  }
`;

B.Link = styled.div`
  display: flex;
  margin-bottom: 249px;

  & a {
    margin-right: 70px;
    display: block;
    position: relative;
    opacity: 0;
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      background: ${theme.colors.black};
      bottom: -2px;
      max-width: 0;
      transition: max-width 400ms ease-out;
    }
    &:hover:after {
      max-width: 100%;
    }
    p {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: -0.01em;
      text-align: left;
    }
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 100px;

    & a {
      margin-right: 0;
      margin-bottom: 25px;
      & p {
        font-size: 28px;
        line-height: 33px;
        letter-spacing: -0.01em;
      }
    }
  }
`;
