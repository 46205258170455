import styled from '@emotion/styled';

export const S = {};

S.Section = styled.div`
  width: 100%;
  max-width: calc(100% - 343px);
  height: 100%;
  max-height: calc(100% - 209px);
  position: absolute;
  top: 149px;
  left: 283px;
  right: 60px;
  transform: translate3d(0, 40%, 0);
  opacity: 0;
  z-index: 500;
  overflow: hidden;
  transition: transform 600ms ease-in-out, max-width 600ms ease-in-out,
    left 600ms ease-in-out, max-height 600ms ease-in-out, top 600ms ease-in-out,
    opacity 600ms ease-in-out, bottom 600ms ease-in-out;
  pointer-events: none;
  &.full {
    transform: translate3d(0, 0%, 0) !important;
    transition-delay: 0ms;
  }
  .bio-page &:nth-child(2):not(.active) {
    display: none;
  }
  &.active.loaded {
    > div:nth-of-type(3) ~ div {
      background: #fff;
    }
    :before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: #fff;
      z-index: -1;
    }
  }
  &:nth-of-type(2).down {
    transition-delay: 250ms;
  }
  body:not(.bio-page) &:nth-of-type(2):not(.selected).bottom-to-top {
    max-height: 0%;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    top: auto;
    bottom: 60px;
    opacity: 1;

    > div:nth-of-type(2) {
      height: calc(100vh - 209px);
      position: absolute;
      bottom: 0;
    }
    &.active {
      max-height: calc(100% - 209px);
      opacity: 1;
    }
  }
  > .banner-arrow-down {
    display: block;
    position: absolute;
    /* top: 85vh; */
    top: 100vh;
    left: 0;
    width: 100%;
    text-align: center;
    margin: auto;
    transform: translate3d(0, -100%, 0);
    padding-bottom: 30px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 300ms ease-in-out;
  }
  &.loaded:not(.scrolled) .banner-arrow-down {
    opacity: 1;
  }
  &.page-loading {
    pointer-events: none;
  }
  > a {
    width: 100%;
    height: 100%;
  }
  &.full {
    max-width: calc(100% - 120px);
    left: 60px;
  }
  &.full.active {
    overflow: visible;
  }
  &.active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    z-index: 600;
    pointer-events: all;
  }
  &.active:not(.selected) {
    transition-delay: 0.1s !important;
    transition: transform 0.4s cubic-bezier(0.2, 0.38, 0.57, 0.89),
      max-width 600ms ease-in-out, left 600ms ease-in-out,
      max-height 600ms ease-in-out, top 600ms ease-in-out,
      opacity 0.4s cubic-bezier(0.2, 0.38, 0.57, 0.89), bottom 600ms ease-in-out;
  }
  &.prev {
    transform: translate3d(0, -40%, 0);
    z-index: 600;
    transition-delay: 0s !important;
    transition: transform 0.4s cubic-bezier(0.35, 0.12, 0.85, 0.63),
      max-width 600ms ease-in-out, left 600ms ease-in-out,
      max-height 600ms ease-in-out, top 600ms ease-in-out,
      opacity 0.4s cubic-bezier(0.35, 0.12, 0.85, 0.63),
      bottom 600ms ease-in-out;
  }
  &.next {
    transform: translate3d(0, 40%, 0);
    z-index: 500;
    transition-delay: 0s !important;
    transition: transform 0.4s cubic-bezier(0.35, 0.12, 0.85, 0.63),
      max-width 600ms ease-in-out, left 600ms ease-in-out,
      max-height 600ms ease-in-out, top 600ms ease-in-out,
      opacity 0.4s cubic-bezier(0.35, 0.12, 0.85, 0.63),
      bottom 600ms ease-in-out;
  }
  &.selected {
    max-width: 100%;
    max-height: 100%;
    top: 0;
    bottom: auto;
    left: 0;
    transform: translate3d(0, 0, 0);
  }
  &.selected a:not(.section-link) {
    pointer-events: none;
  }

  &.selected.loaded {
    overflow: auto;
  }
  @media (max-height: 700px) {
    max-height: calc(100% - 159px);
    top: 99px;
    body:not(.bio-page) &:nth-of-type(2):not(.selected).bottom-to-top {
      max-height: 0%;

      bottom: 60px;

      > div:nth-of-type(2) {
        height: calc(100vh - 159px);
      }
      &.active {
        max-height: calc(100% - 159px);
      }
    }
  }
  @media (max-width: 800px) {
    max-width: calc(100% - 120px);
    max-height: calc(100% - 79px);
    top: 79px;
    left: 120px;
    > .banner-arrow-down {
      top: 85vh;
    }
    &.prev {
      transform: translate3d(0, -20%, 0);
    }
    &.next {
      transform: translate3d(0, 20%, 0);
    }
    body:not(.bio-page) &:nth-of-type(2):not(.selected).bottom-to-top {
      bottom: 0;

      > div:nth-of-type(2) {
        height: calc(100vh - 79px);
      }
      &.active {
        max-height: calc(100% - 79px);
      }
    }

    &.full {
      max-width: calc(100% - 30px);
      left: 30px;
    }
  }
  @media (max-width: 600px) {
    max-width: calc(100% - 60px);
    max-height: calc(100% - 79px);
    top: 79px;
    left: 60px;
  } ;
`;
