import styled from '@emotion/styled';

export const D = {};

D.Container = styled.div({
  width: '100%',
  padding: '400px 19.65% 400px 11.94%',
  whiteSpace: 'pre-line',

  display: 'flex',
  alignItems: 'center',
  // background: '#fff',
  '& > p': {
    fontSize: ' 27px!important',
    lineHeight: '36px!important',
    letterSpacing: '-0.01em!important',
    '& span,& textarea': {
      display: 'inline-block',
      fontSize: ' 27px!important',
      lineHeight: '36px!important',
      letterSpacing: '-0.01em!important',
    },
  },
  '@media (max-width:1600px)': {
    padding: '300px 19.65% 300px 11.94%',
  },
  '@media (max-width:1024px)': {
    padding: '200px 19.65% 200px 11.94%',
  },
  '@media (max-width:800px)': {
    padding: '70px 46px 70px 30px',
    minHeight: 'auto',
    '& > p': {
      fontSize: '20px!important',
      lineHeight: '30px!important',
      letterSpacing: '-0.05em!important',
      textAlign: 'left',
      '& span,& textarea': {
        fontSize: '20px!important',
        lineHeight: '30px!important',
        letterSpacing: '-0.05em!important',
        textAlign: 'left',
      },
    },
  },
});
