import React, { useRef, useEffect } from 'react';

import Content from '../Content/Content';

import { F } from './FullImage.styles';

export default function FullImage({
  mainColor,
  image,
  mobileImage,
  alt,
  loadCallBack,

  ...props
}) {
  const imageRef = useRef();
  const handleLoad = (e) => setImageLoad(e);
  const setImageLoad = (e) => {
    loadCallBack(e);
  };

  useEffect(() => {
    if (image.current && image.current.complete) {
      setImageLoad();
    }
  }, []);

  return (
    <Content
      backgroundColor={mainColor}
      isShown={true}
      isFull={true}
      {...props}
    >
      <F.FullImage>
        <picture>
          <source media='(max-width: 600px)' srcSet={mobileImage} />
          <source media='(min-width: 601px)' srcSet={image} />
          <img
            src={image}
            alt={alt}
            onLoad={(e) => handleLoad(e)}
            ref={imageRef}
          />
        </picture>
      </F.FullImage>
    </Content>
  );
}
