import anime from 'animejs';
import React, { createRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Link from '../../components/Link/Link';
import Text from '../../components/Text/Text';

import { awards } from '../../constants/awards';
import { selectIsBioPageOpen } from '../../features/selectors';
import { addClass, removeClass } from '../../utils/evensts';
import { resetAnimations } from '../../utils/resetAnimated';
import { scrollAnimation } from '../../utils/scrollAnimations';
import { _spanToText, wrapTextToSpan } from '../../utils/textConvertor';
import { useWindowDimensions } from '../../utils/window';

import { B } from './BioContent.styles';

let scrollBioTop = 0;

const BioContent = () => {
  const isBioOpen = useSelector(selectIsBioPageOpen);
  const bioRef = createRef();
  const { width } = useWindowDimensions();
  const duration = width < 600 ? 600 : 600;

  function _handleScroll(event) {
    scrollAnimation(bioRef.current.scrollTop);

    if (typeof event.deltaY === 'undefined') {
      let result = event.target.scrollTop < scrollBioTop;
      if (scrollBioTop < 200) result = true;
      _handleSwipe(result);
      scrollBioTop = event.target.scrollTop;
    } else {
      _handleSwipe(event.deltaY < 0, event);
    }
  }

  function _handleSwipe(e) {
    if (!isBioOpen) return;
    if (!e) addClass('header', 'header-up');
    else removeClass('header', 'header-up');
  }

  useEffect(() => {
    if (isBioOpen) {
      scrollAnimation(bioRef.current.scrollTop);
      const ps = document.querySelectorAll('.bio-praph');
      for (let index = 0; index < ps.length; index++) {
        wrapTextToSpan(ps[index]);
      }

      setTimeout(() => {
        anime({
          targets: '.bio-praph span',
          opacity: [0, 1],
          translateY: [40, 0],
          duration: duration,
          easing: 'easeOutCubic',
          delay: function (el, i, l) {
            return 100 + i * 10;
          },
        });
      }, 0);
    } else {
      setTimeout(() => {
        _spanToText(document.getElementById('bio-content'));
        resetAnimations('#bio-content');
      }, 500);
    }

    if (typeof window !== 'undefined') {
      if (window.location.hash === '#bio__contact-list' && isBioOpen) {
        setTimeout(() => {
          document
            .getElementById('bio-content')
            .scrollTo(0, document.getElementById('bio-content').scrollHeight);
        }, 200);
      }
    }

    return () => {
      anime({
        targets: '.bio-praph span',
        opacity: [1, 0],
        translateY: [0, 40],
        duration: duration,
        easing: 'easeOutCubic',
      });
    };
  }, [isBioOpen]);

  return (
    <B.Content
      className={isBioOpen ? 'active' : ''}
      id='bio-content'
      ref={bioRef}
      onScroll={(e) => _handleScroll(e)}
    >
      <B.Container>
        <Text font='bold' size='xl' as='h2' className='bio-first wider'>
          Bio
        </Text>
        <Text size='mdx' className='bio-praph'>
          Hello, I’m Ufuk from Istanbul. I have 22 years of work experience in
          art direction and digital design. I hold a graduate degree in
          statistics and a master's degree in game design – a seemingly odd
          combination that proved to be quite useful. I started my career as an
          art director, and created digital campaigns and advergames for 12
          years. With the emergence of Web 2.0, I focused on designing
          interfaces, digital products and services. I've also designed loyalty
          programs and gamified experiences for clients of all sizes.
        </Text>
        <br />
        <br />
        <Text size='mdx' className=' bio-praph'>
          Different verticals in my career allowed me to utilize a holistic
          approach on product design including conceptualization, design
          language, design systems, gamification, functionality, and team
          leadership.
        </Text>
        <br />
        <br />
        <Text size='mdx' className=' bio-praph'>
          I have worked for industry leading companies like Renault, Unilever,
          L’Oréal, Huawei, Mastercard, Nike, and participated in various global
          projects as lead designer.
        </Text>
        <br />
        <br />
        <Text size='mdx' className=' bio-praph'>
          The work I've done has been acknowledged with more than 40 national
          and international awards. Some of these include Clio, Golden Drum,
          Midas, Eurobest, Epica, and Turkey's first ever Cannes Lion in digital
          category.
        </Text>
        <B.Part>
          <Text
            font='bold'
            size='xl'
            as='h2'
            data-top='.8'
            className='scroll-animation-elem'
            data-animate={
              width > 600
                ? '{"opacity":[0, 1],"translateY":[120,0] , "duration": 600}'
                : '{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            }
          >
            Awards
          </Text>
          <>
            {awards.map((parent) => (
              <B.AwardRow
                key={parent.index}
                data-top='.8'
                className={`${parent.class} scroll-animation-elem childs`}
                data-delay='150'
                data-animate={
                  width > 600
                    ? '{"opacity":[0, 1],"translateY":[80,0] , "duration": 600}'
                    : '{"opacity":[0, 1],"translateY":[80,0] , "duration": 800}'
                }
              >
                {parent.childs.map((child, index) => (
                  <B.Award key={index}>
                    <Text as='h4' size='l'>
                      {child.title}
                      <Text as='span' size='sm' color='#BFBFBF' font='bold'>
                        x{child.times}
                      </Text>
                    </Text>

                    {child.details.map((detail, idx) => (
                      <Text key={idx} size='sx'>
                        {detail}
                      </Text>
                    ))}
                  </B.Award>
                ))}
              </B.AwardRow>
            ))}
          </>
        </B.Part>
        <B.Part>
          <Text
            font='bold'
            size='xl'
            as='h2'
            data-top='.8'
            className='scroll-animation-elem bio-contact-header wider last'
            id='bio__contact-list-header'
            data-animate={
              width > 600
                ? '{"opacity":[0, 1],"translateY":[120,0] , "duration": 600, "delay":100}'
                : '{"opacity":[0, 1],"translateY":[120,0] , "duration": 800, "delay":100}'
            }
          >
            Contact
          </Text>
          <B.Link
            data-top='.8'
            className='scroll-animation-elem childs'
            data-animate={
              width > 600
                ? '{"opacity":[0, 1],"translateY":[80,0] , "duration": 800}'
                : '{"opacity":[0, 1],"translateY":[80,0] , "duration": 1000}'
            }
          >
            <Link href='mailto:hello@ufuk.work' isExternal={true}>
              <Text target='_blank'>Email</Text>
            </Link>
            <Link
              href='https://www.linkedin.com/in/icedevice/'
              isExternal={true}
              target='_blank'
            >
              <Text delay={100}>Linkedin</Text>
            </Link>
            <Link
              href='https://www.behance.net/icedevice'
              isExternal={true}
              target='_blank'
            >
              <Text delay={200}>Behance</Text>
            </Link>
            <Link
              href='https://www.instagram.com/ufuk.cetincan/'
              isExternal={true}
              target='_blank'
            >
              <Text delay={300}>Instagram</Text>
            </Link>
          </B.Link>
        </B.Part>
      </B.Container>
    </B.Content>
  );
};

export default BioContent;
