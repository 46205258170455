import React, { useState } from 'react';

import Section from '../../components/Section/Section';
import Description from '../../components/Description';
import sections from '../../constants/sections';
import Content from '../../components/Content';
import Picture from '../../components/Picture';
import Text from '../../components/Text/Text';
import Video from '../../components/Video';

import Footer from '../../components/Footer/Footer';
import { useWindowDimensions } from '../../utils/window';

import { C } from '../Containers.styles';

import selfImage from '../../assets/images/sa/selfapp-app.jpg';
import selfMobileImage from '../../assets/images/sa/selfapp-app-mobile.jpg';
import selfTextImage from '../../assets/images/sa/selfapp-self-text.jpg';
import selfMobileTextImage from '../../assets/images/sa/selfapp-self-text-mobile.jpg';
import screensImage from '../../assets/images/sa/selfapp-screens.jpg';
import screensMobileImage from '../../assets/images/sa/selfapp-screens-mobile.jpg';
import watchImage from '../../assets/images/sa/selfapp-watch.jpg';
import watchMobileImage from '../../assets/images/sa/selfapp-watch-mobile.jpg';
import sa736Image from '../../assets/images/sa/selfapp-736.jpg';
import sa736MobileImage from '../../assets/images/sa/selfapp-736-mobile.jpg';
import sa536Image from '../../assets/images/sa/selfapp-536.jpg';
import sa536MobileImage from '../../assets/images/sa/selfapp-536-mobile.jpg';
import joyImage from '../../assets/images/sa/selapp-joy.jpg';
import joyMobileImage from '../../assets/images/sa/selapp-joy-mobile.jpg';
import footerImage from '../../assets/images/sa/selfapp-footer.jpg';
import footerMobileImage from '../../assets/images/sa/selfapp-footer-mobile.jpg';

import { ContentText } from '../../components/ContentText/ContentText';

import desktopVideo from '../../assets/videos/self_desktop.mp4';
import mobileVideo from '../../assets/videos/self_mobile.mp4';

const SelfApp = () => {
  const name = 'SelfApp';
  const { description } = sections[name];
  const [loadedImageCount, setLoadedImageCount] = useState(0);
  const { width } = useWindowDimensions();
  const itemCount = width < 768 ? 8 : 9;

  return (
    <Section
      sectionName={name}
      itemCount={itemCount}
      loadedImageCount={loadedImageCount}
    >
      <Description desc={description} />
      <Content isShown={true} mobileFull={true}>
        <C.FullImage
          className='scroll-animation-elem'
          data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        >
          <Picture
            mobile={selfMobileImage}
            desktop={selfImage}
            alt='SelfApp screen'
            onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
          />
        </C.FullImage>
      </Content>
      <Content isShown={true} mobileFull={true}>
        <C.HalfParent>
          <ContentText title='THE SELF EXPLORATION'>
            The app logo depicts the solid sense of "self" being deconstructed,
            dissected, and finally being dissolved as one dives deeper and
            deeper into meditation.
          </ContentText>

          <C.Half
            className='scroll-animation-elem'
            data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
          >
            <Picture
              mobile={selfMobileTextImage}
              desktop={selfTextImage}
              alt='SelfApp screen'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
            />
          </C.Half>
        </C.HalfParent>
      </Content>
      <Content isShown={true} mobileFull={true}>
        <C.FullImage>
          <Picture
            mobile={screensMobileImage}
            desktop={screensImage}
            alt='SelfApp screen'
            onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
            className='scroll-animation-elem'
            data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
          />
        </C.FullImage>
      </Content>
      <Content isShown={true} mobileFull={true}>
        <C.HalfParent>
          <ContentText title='UTILIZING HARDWARE & SENSORS'>
            Voice recognition feature records the sensations noted aloud, and
            decodes them into session log graphs. Stillness gyrometer measures
            users little movements during the session. When coupled with
            wearables, blood pressure and pulse count is also recorded.
          </ContentText>
          <C.Half>
            <Picture
              mobile={watchMobileImage}
              desktop={watchImage}
              alt='SelfApp screen'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              className='scroll-animation-elem'
              data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.Half>
        </C.HalfParent>
      </Content>
      <Content isShown={true} mobileFull={true}>
        <C.FullImage>
          <Picture
            mobile={sa736MobileImage}
            desktop={sa736Image}
            alt='SelfApp screen'
            onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
            className='scroll-animation-elem'
            data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
          />
        </C.FullImage>
      </Content>
      <Content isShown={true}>
        <C.Description>
          <Text
            as='h4'
            size='sx'
            font='bold'
            className='scroll-animation-elem'
            data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
          >
            SESSION REPORTS
          </Text>
          <ContentText notHalf>
            Afterthoughts about a meditation session can be biased, and memories
            might get distorted over time. Self App presents an objective and
            detailed system for keeping track of your meditation sessions. The
            app gathers data from the sensors, and combines them with user
            input. Meditators can log their experience with respective intensity
            factor for each emotion.
          </ContentText>
        </C.Description>
      </Content>
      <div>
        <Content
          isShown={true}
          isFull={true}
          className='scroll-animation-elem'
          data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
        >
          <Video
            isFull
            video={desktopVideo}
            mobileVideo={mobileVideo}
            onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
          />
        </Content>
      </div>
      <Content isShown={true} mobileFull={true}>
        <C.HalfParent>
          <C.Half>
            <Picture
              mobile={sa536MobileImage}
              desktop={sa536Image}
              alt='SelfApp screen'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              className='scroll-animation-elem'
              data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.Half>
          <C.Half>
            <Picture
              mobile={joyMobileImage}
              desktop={joyImage}
              alt='SelfApp screen'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              className='scroll-animation-elem'
              data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800,"delay":100}'
            />
          </C.Half>
        </C.HalfParent>
      </Content>
      <Content isShown={true}>
        <C.Description>
          <Text
            as='h4'
            size='sx'
            font='bold'
            className='scroll-animation-elem'
            data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
          >
            GAMIFICATION & PERSONAL EXPERIENCE
          </Text>
          <ContentText notHalf>
            The app utilizes gamification methods to keep users interested and
            motivated.The mythical stones are unlocked by completing milestones,
            which actually is a result of dedicated practice. Users can also
            track their personal journey via “karmic journal”, a personal
            session blog which can also be published online.
          </ContentText>
        </C.Description>
      </Content>
      <Content isShown={true} mobileFull={true}>
        <C.FullImage>
          <C.LastItem>
            <Picture
              mobile={footerMobileImage}
              desktop={footerImage}
              alt='SelfApp screen'
              onLoad={() => setLoadedImageCount((prevcount) => prevcount + 1)}
              className='scroll-animation-elem'
              data-animate='{"opacity":[0, 1],"translateY":[120,0] , "duration": 800}'
            />
          </C.LastItem>
        </C.FullImage>
      </Content>
      <Footer />
    </Section>
  );
};
export default SelfApp;
