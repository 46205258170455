export const awards = [
  {
    index: 1,
    childs: [
      {
        title: "Cannes Lions",
        times: 1,
        details: ["Digital Bronze"],
      },
      {
        title: "Eurobest",
        times: 1,
        details: ["Gold Award"],
      },
      {
        title: "Golden Drum",
        times: 2,
        details: ["2x Silver Drum"],
      },
    ],
  },
  {
    index: 2,
    childs: [
      {
        title: "Clio",
        times: 2,
        details: ["Gold Clio", "Bronze Clio"],
      },
      {
        title: "Bronze Clio",
        times: 1,
        details: ["Silver Epica"],
      },
      {
        title: "FWA",
        times: 1,
        details: ["Site of the Day"],
      },
    ],
  },
  {
    index: 3,
    childs: [
      {
        title: "Midas Awards",
        times: 11,
        details: ["5x Gold Midas", "5x Silver Midas", "Best Advergame"],
      },
      {
        title: "W3 Awards",
        times: 4,
        details: ["Best in Show", "Gold / Experimental", "2x Gold / Website"],
      },
      {
        title: "Kristal Elma",
        times: 14,
        details: [
          "6x Gold Digital",
          "2x Silver Digital",
          "3x Bronze Digital",
          "Best Art Direction",
          "Best Advergame",
          "Excellence in App. Design",
        ],
      },
    ],
  },
  {
    index: 4,
    class: "double",
    childs: [
      {
        title: "Digital Age",
        times: 2,
        details: ["Creative User Interface", "Advergame of the Year"],
      },
      {
        title: "European Digital Comm. Awards",
        times: 2,
        details: ["Gold / Microsite", "Bronze / Storytelling"],
      },
    ],
  },
]
