import React from 'react';
import { useSelector } from 'react-redux';
import { selectAllBannerImageLoadedCount } from '../../features/selectors';
import Loading from '../Loading/Loading';
import { L } from './MainPageLoader.styles';

const MainPageLoader = () => {
  const allBannerImageLoadedCount = useSelector(
    selectAllBannerImageLoadedCount
  );

  return (
    <L.Container>
      <Loading
        itemCount={10}
        loadedImageCount={allBannerImageLoadedCount.length}
        mainPage
      />
    </L.Container>
  );
};

export default MainPageLoader;
