import { globalHistory } from '@reach/router';
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

import { C } from './Containers.styles';

import Alawwalbank from './Alawwalbank';
import BNCFFShowcase from './BNCFFShowcase';
import BioContent from './BioContent/BioContent';
import BunkerCustoms from './BunkerCustoms';
import FifthPosition from './FifthPosition';
import Home from './Home';
import LorealContentPro from './LorealContentPro';
import Lsa from './Lsa';
import Numbers from './Numbers';
import SelfApp from './SelfApp';
import SpitzGermany from './SpitzGermany';
import Wamo from './Wamo';

import { useSelector } from 'react-redux';

import Texts from './Texts/Texts';

import ArrowRight from '../assets/images/arrow-right.svg';

import {
  popBio,
  popDetailFromBio,
  popToHome,
  popToSection,
  popToWork,
  popToWorkFromBio,
  pushBio,
  pushToHome,
  pushToHomeFrombio,
  pushToSection,
  pushToWork,
  pushToWorkFromBio,
} from '../helpers/urlTracker';

import Pagination from '../components/Pagination';
import Text from '../components/Text/Text';
import {
  _bioToHomePop,
  _detailToDetail,
  _handleMainPageScroll,
  _popToSection,
  _returnUrlSectionIndex,
  clearAllTimeouts,
} from '../helpers/helper';
import { hasClass, removeClass } from '../utils/evensts';

import Button from '../components/Button/Button';
import MainPageLoader from '../components/MainPageLoader';
import NextSection from '../components/NextSection';
import sections from '../constants/sections';
import {
  selectIsBioPageOpen,
  selectIsDetailHeaderAndContentShown,
  selectIsHomeTextGone,
  selectIsMainLoaded,
  selectMainPageActiveIndex,
  selectMainPageScrolldirection,
  selectSelectedSectionIndex,
} from '../features/selectors';

const totalSectionCount = 9;

const MainContainer = ({ children }) => {
  const mainPageActiveIndex = useSelector(selectMainPageActiveIndex);
  const selectedSectionIndex = useSelector(selectSelectedSectionIndex);
  const isBioPageOpen = useSelector(selectIsBioPageOpen);
  const isHomeTextGone = useSelector(selectIsHomeTextGone);
  const isMainLoaded = useSelector(selectIsMainLoaded);
  const mainPageScrolldirection = useSelector(selectMainPageScrolldirection);
  const IsDetailHeaderAndContentShown = useSelector(
    selectIsDetailHeaderAndContentShown
  );

  useEffect(() => {
    globalHistory.listen(({ action }) => {
      removeClass(document.getElementsByTagName('HEADER')[0], 'light');
      removeClass('header', 'header-up');
      clearAllTimeouts();
      const index = _returnUrlSectionIndex();
      const bioOpen = hasClass(document.body, 'bio-page');

      if (action === 'PUSH') {
        if (window.location.hash === '#work' && !bioOpen) {
          pushToWork();
          return;
        }
        if (window.location.hash === '#work' && bioOpen) {
          pushToWorkFromBio();
          return;
        } else if (
          window.location.pathname === '/Biography' ||
          window.location.pathname === '/Biography#bio__contact-list'
        ) {
          pushBio();
        } else if (index === 0 && bioOpen) {
          pushToHomeFrombio();
          return;
        } else if (index === 0) {
          pushToHome();
          return;
        } else if (
          hasClass(
            document.getElementById('content-container'),
            'active-section'
          ) &&
          window.location.pathname.length > 1
        ) {
          _detailToDetail();
          return;
        } else pushToSection();
      }

      if (action === 'POP') {
        if (window.location.hash === '#work' && bioOpen) {
          popToWorkFromBio();
          return;
        } else if (window.location.hash === '#work') {
          popToWork();
          return;
        } else if (
          window.location.pathname === '/Biography' ||
          window.location.pathname === '/Biography#bio__contact-list'
        ) {
          popBio();
          return;
        } else if (
          window.location.pathname.length > 0 &&
          hasClass(document.getElementsByTagName('HEADER')[0], 'bio') &&
          hasClass(document.getElementById('content-box'), 'detail-page')
        ) {
          popDetailFromBio();
        } else if (
          window.location.pathname.length > 0 &&
          hasClass(document.getElementsByTagName('HEADER')[0], 'bio') &&
          !hasClass(document.getElementById('content-box'), 'detail-page')
        ) {
          _bioToHomePop();
          return;
        } else if (window.location.pathname.length > 0) {
          _popToSection();
        } else if (index === 0) popToHome();
        else if (
          hasClass(
            document.getElementById('content-container'),
            'active-section'
          ) &&
          window.location.pathname.length > 1
        ) {
          _detailToDetail();
          return;
        } else popToSection();
      }
    });
  }, []);

  const getSectionLink = () => {
    const item = Object.keys(sections).filter(function (key) {
      return sections[key].index === mainPageActiveIndex;
    })[0];

    return item;
  };

  const scrollFunc = (direction) => {
    if (selectedSectionIndex < 0) {
      let newActiveMainPageIndex =
        mainPageActiveIndex + (direction === 'up' ? -1 : +1);

      //IF LAST OR FIRST RETURN
      if (
        newActiveMainPageIndex < 0 ||
        newActiveMainPageIndex > totalSectionCount
      )
        return;

      if (newActiveMainPageIndex > 0) {
        window.history.pushState({}, '', '/#work');
      } else window.history.pushState({}, '', '/');

      _handleMainPageScroll(newActiveMainPageIndex, direction);
    }
  };

  const prevIndex = () => scrollFunc('up');
  const nextIndex = () => scrollFunc('down');

  const homeClass =
    mainPageActiveIndex === 0 && selectedSectionIndex === -1 ? 'home-page' : '';
  const workClass =
    mainPageActiveIndex > 0 && selectedSectionIndex === -1 ? 'work-page' : '';
  const detailClass = selectedSectionIndex !== -1 ? 'detail-page' : '';

  return (
    <C.FullHeight
      className={`${homeClass} ${workClass} ${detailClass}`}
      id='content-box'
    >
      {!isMainLoaded && <MainPageLoader />}
      <C.ContentBody
        className={`${isBioPageOpen ? 'slided' : ''}`}
        id='content-body'
      >
        <C.SectionContainer
          upHandler={prevIndex}
          downHandler={nextIndex}
          className={selectedSectionIndex !== -1 ? 'active-section' : ''}
          id='content-container'
        >
          {children}
        </C.SectionContainer>
        {IsDetailHeaderAndContentShown && !isBioPageOpen && (
          <NextSection
            name={
              sections[Object.keys(sections)[selectedSectionIndex]].nextItem
            }
          />
        )}
        <Texts
          delayClass={`${
            mainPageScrolldirection === 'down' && mainPageActiveIndex === 1
              ? 'down'
              : ''
          }`}
        />
        <Numbers
          delayClass={`${
            mainPageScrolldirection === 'down' && mainPageActiveIndex === 1
              ? 'down'
              : ''
          }`}
        />
        <C.ReadButton
          className={`${
            !isHomeTextGone && mainPageActiveIndex > 0 ? '' : 'gone'
          } ${
            mainPageScrolldirection === 'down' && mainPageActiveIndex === 1
              ? 'down'
              : ''
          }`}
        >
          <div>
            <Button onClick={() => navigate(`/${getSectionLink()}`)}>
              <Text as='span'>Read the story</Text>
              <Text as='span'>Explore</Text>
              <img src={ArrowRight} alt='arrow right' />
            </Button>
          </div>
        </C.ReadButton>
        <Pagination />
      </C.ContentBody>
      <BioContent />
    </C.FullHeight>
  );
};

const Container = () => {
  return (
    <MainContainer>
      <Home />
      <LorealContentPro />
      <BNCFFShowcase />
      <SpitzGermany />
      <Wamo />
      <SelfApp />
      <FifthPosition />
      <BunkerCustoms />
      <Lsa />
      <Alawwalbank />
    </MainContainer>
  );
};

export default Container;
