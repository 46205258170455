import styled from "@emotion/styled"

export const B = {}

B.FontContent = styled.div({
  width: "80%",
  maxWidth: 830,
  "& img": {
    width: "100%",
  },
})
